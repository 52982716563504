import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import capture from './Animation - 1735561736666.json';
import './Header.css';
import logo from '../assets/img/LogoSpc.png';
import homeLogo from '../assets/img/CM.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isHomeSection, setIsHomeSection] = useState(true);
  const [activeSection, setActiveSection] = useState('home');
  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
      setShowScrollButtons(window.scrollY > 200);
      
      // Get all sections
      const sections = ['home', 'about', 'features', 'Pricing', 'contact', 'Login'];
      const scrollPosition = window.scrollY;

      // Find the current section
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const top = element.offsetTop - 100; // Adjust for header height
          const height = element.offsetHeight;
          
          if (scrollPosition >= top && scrollPosition < top + height) {
            setActiveSection(section);
            setIsHomeSection(section === 'home');
            break;
          }
        }
      }

      // Special case for home section when at the very top
      if (scrollPosition <= 100) {
        setActiveSection('home');
        setIsHomeSection(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Initial check
    handleScroll();
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
    document.body.classList.toggle('mobile-nav-active');
    document.getElementById('navmenu').classList.toggle('mobile-nav-active');
  };

  const handleNavClick = (sectionId) => {
    // Special handling for home section
    if (sectionId === 'home') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        const headerHeight = document.getElementById('header')?.offsetHeight || 80;
        const targetPosition = element.offsetTop - headerHeight;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth'
        });
      }
    }

    // Close mobile menu if open
    if (isMobileNavOpen) {
      toggleMobileNav();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <header id="header" className={`header fixed-top ${isSticky ? 'sticked' : ''} ${isHomeSection ? 'home-section' : ''}`}>
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <a 
            href="#home" 
            onClick={(e) => {
              e.preventDefault();
              handleNavClick('home');
            }} 
            className="logo d-flex align-items-center me-auto me-lg-0"
          >
            <div className="logo" style={{ position: 'relative', display: 'inline-block' }}>
              {isHomeSection ? (
                <>
                <img src={homeLogo} alt="Logo" />
                <div 
                  style={{
                    position: 'absolute',
                    top: '-27%',
                    right: '-5%',
                    width: '60%',
                    zIndex: 10,
                  }}
                >
                </div>
              </>
              ) : (
                <>
                  <img src={logo} alt="Logo" />
                  <div 
                    style={{
                      position: 'absolute',
                      top: '-5%',
                      right: '15%',
                      width: '53%',
                      zIndex: 10,
                    }}
                  >
                  </div>
                </>
              )}
            </div>
          </a>

          <div className="icon-container">
            <div className="error-icon">
              {/* Your error icon here */}
            </div>
            <button
              className="mobile-nav-toggle d-lg-none"
              onClick={toggleMobileNav}
              aria-label="Toggle Navigation Menu"
            >
              <i className={`bi ${isMobileNavOpen ? 'bi-x' : 'bi-list'}`}></i>
            </button>
          </div>

          <nav id="navmenu" className={`navmenu ${isMobileNavOpen ? 'mobile-nav-active' : ''}`}>
            <ul>
              <li><a href="#home" onClick={(e) => { e.preventDefault(); handleNavClick('home'); }} className={activeSection === 'home' ? 'active' : ''}>Home</a></li>
              <li><a href="#about" onClick={(e) => { e.preventDefault(); handleNavClick('about'); }} className={activeSection === 'about' ? 'active' : ''}>About</a></li>
              <li><a href="#features" onClick={(e) => { e.preventDefault(); handleNavClick('features'); }} className={activeSection === 'features' ? 'active' : ''}>Features</a></li>
              <li><a href="#Pricing" onClick={(e) => { e.preventDefault(); handleNavClick('Pricing'); }} className={activeSection === 'Pricing' ? 'active' : ''}>Pricing</a></li>
              <li><a href="#contact" onClick={(e) => { e.preventDefault(); handleNavClick('contact'); }} className={activeSection === 'contact' ? 'active' : ''}>Contact</a></li>
              <li>
  <Link to="/login" className={activeSection === 'Login' ? 'active' : ''}>Login</Link>
</li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Scroll to top button */}
      <div className={`scroll-buttons top ${showScrollButtons ? 'show' : ''}`}>
        <button 
          className="scroll-button" 
          onClick={scrollToTop}
          aria-label="Scroll to top"
        >
          <i className="bi bi-arrow-up"></i>
        </button>
      </div>

      {/* Scroll to bottom button */}
      <div className={`scroll-buttons bottom ${showScrollButtons ? 'show' : ''}`}>
        <button 
          className="scroll-button" 
          onClick={scrollToBottom}
          aria-label="Scroll to bottom"
        >
          <i className="bi bi-arrow-down"></i>
        </button>
      </div>
    </>
  );
};

export default Header;
