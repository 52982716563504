import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import api from '../../Authenticaltion/api';
import './HolidaysPage.css';
import { useLoading } from '../../LoadingContext';

const Holidays = () => {
  const [data, setData] = useState([]);
  const { updateLoading } = useLoading();

  const fetchDataFromApi = async () => {
    updateLoading(true);
    try {
      const response = await api.get('/Holidays');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      updateLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const getHolidayStatus = (startDate) => {
    const startDateObj = new Date(startDate.split('-').reverse().join('-'));
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    startDateObj.setHours(0, 0, 0, 0);

    if (startDateObj.getTime() === currentDate.getTime()) {
      return 'current';
    } else if (currentDate < startDateObj) {
      return 'upcoming';
    }
    return 'past';
  };

  const columns = [
    {
      dataField: 'Sl_No',
      text: '#',
      headerStyle: { width: '60px' }
    },
    {
      dataField: 'Holiday_Name',
      text: 'Holiday Name',
      formatter: (cell, row) => {
        const status = getHolidayStatus(row.StartDate);
        return (
          <span className={`holiday-badge holiday-${status}`}>
            {cell}
          </span>
        );
      },
    },
    {
      dataField: 'StartDate',
      text: 'Start Date',
      formatter: (cell) => {
        const [day, month, year] = cell.split('-');
        return `${day} ${new Date(year, month - 1).toLocaleString('default', { month: 'short' })} ${year}`;
      }
    },
    {
      dataField: 'End_Date',
      text: 'End Date',
      formatter: (cell) => {
        if (!cell) return '-';
        const [day, month, year] = cell.split('-');
        return `${day} ${new Date(year, month - 1).toLocaleString('default', { month: 'short' })} ${year}`;
      }
    },
    {
      dataField: 'No_Of_Days',
      text: 'Duration',
      formatter: (cell, row) => {
        const startDateParts = row.StartDate.split('-');
        const endDateParts = row.End_Date ? row.End_Date.split('-') : row.StartDate.split('-');

        const startDate = new Date(
          startDateParts[2],
          startDateParts[1] - 1,
          startDateParts[0]
        );
        const endDate = new Date(
          endDateParts[2],
          endDateParts[1] - 1,
          endDateParts[0]
        );

        const days = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
        return `${days} day${days > 1 ? 's' : ''}`;
      },
    },
    {
      dataField: 'Day_Of_Week',
      text: 'Day',
      formatter: (cell, row) => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const startDateParts = row.StartDate.split('-');
        const startDate = new Date(
          startDateParts[2],
          startDateParts[1] - 1,
          startDateParts[0]
        );
        return daysOfWeek[startDate.getDay()];
      },
    },
  ];

  const options = {
    custom: true,
    totalSize: data.length,
    sizePerPage: 10,
    pageStartIndex: 1,
    firstPageText: '⟨⟨',
    prePageText: '⟨',
    nextPageText: '⟩',
    lastPageText: '⟩⟩',
  };

  const Legend = () => (
    <div className="holiday-legend">
      <div className="legend-item">
        <span className="legend-dot legend-upcoming"></span>
        <span>Upcoming</span>
      </div>
      <div className="legend-item">
        <span className="legend-dot legend-current"></span>
        <span>Today</span>
      </div>
      <div className="legend-item">
        <span className="legend-dot legend-past"></span>
        <span>Past</span>
      </div>
    </div>
  );

  return (
    <div className="holidays-container">
      <h1 className="holidays-title">Holiday Calendar</h1>
      {data.length === 0 ? (
        <div className="text-center py-5">
          <p className="text-muted">No holidays available</p>
        </div>
      ) : (
        <>
          <Legend />
          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <div className="table-responsive">
                  <div className="scroll-table-container">
                    <div className="scroll-table">
                      <BootstrapTable
                        keyField="Sl_No"
                        data={data}
                        columns={columns}
                        bordered={false}
                        classes="table"
                        wrapperClasses="table-responsive"
                        {...paginationTableProps}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            )}
          </PaginationProvider>
        </>
      )}
    </div>
  );
};

export default Holidays;
