import React from 'react';
import GLightbox from 'glightbox';
import Lottie from 'lottie-react';
import { ReactTyped } from 'react-typed';
import animationdata3 from './Animation - 1722938763901.json';
import './Hero.css';
import ChatBoard from './ChatBoard';

const Hero = () => {
  React.useEffect(() => {
    GLightbox({
      selector: '.glightbox'
    });
  }, []);

  return (
    <section id="hero" className="hero section dark-background">
      <div className="hero-overlay"></div>

      <div className="container">
        <div className="row gy-4 justify-content-between">
          <div className="col-lg-5 order-lg-last hero-img" data-aos="zoom-out" data-aos-delay="100" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Lottie 
              animationData={animationdata3} 
              style={{ 
                width: '100%',
                minWidth: '324px',
                maxWidth: '800px',
                height: 'auto',
                transform: 'scale(1.2)',
              }}
              loop={true}
              rendererSettings={{
                preserveAspectRatio: 'xMidYMid slice'
              }}
            />
          </div>

          <div className="col-lg-6 text-start d-flex flex-column justify-content-center hero-content" data-aos="fade-in">
            <div className="badge-container mb-4">
              <span className="industry-badge">Enterprise Solutions</span>
              <a
                href="/login"
                className="btn-watch d-flex align-items-center gap-2"
              >
                <div className="play-icon-circle">
                  <i className="bi bi-person-fill"></i>
                </div>
                <span>Login</span>
              </a>
            </div>
           
            <h2 className="hero-title">
              Transform Your <br />
              <span className="gradient-text">Digital Infrastructure</span> with<br />
              <span className="typed-container">
                <ReactTyped 
                  className="typed-text"
                  strings={[
                    'Cloud Solutions',
                    'Smart Analytics',
                    'AI Integration'
                  ]} 
                  typeSpeed={150} 
                  backSpeed={100} 
                  loop 
                />
              </span>
            </h2>
            <p className="hero-description">
              Empower your enterprise with cutting-edge technology solutions. 
              From cloud infrastructure to intelligent analytics, we deliver 
              scalable solutions that drive innovation and growth.
            </p>
            <div className="cta-container d-flex align-items-center gap-4 mt-4">
              <a 
                href="/solutions" 
                className="btn-primary-gradient d-flex align-items-center gap-2"
              >
                <span>Explore Solutions</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path 
                    fill="currentColor" 
                    d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  />
                </svg>
              </a>
              <a
                href="/login"
                className="btn-watch d-flex align-items-center gap-2"
              >
                <div className="play-icon-circle">
                  <i className="bi bi-person-fill"></i>
                </div>
              </a>
            </div>
            <div className="stats-container d-flex gap-5 mt-5">
              <div className="stat-item">
                <h3 className="stat-number">98%</h3>
                <p className="stat-label">Client Satisfaction</p>
              </div>
              <div className="stat-item">
                <h3 className="stat-number">500+</h3>
                <p className="stat-label">Enterprise Clients</p>
              </div>
              <div className="stat-item">
                <h3 className="stat-number">24/7</h3>
                <p className="stat-label">Support Available</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChatBoard />
      <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none">
        <defs>
          <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g className="wave1">
          <use xlinkHref="#wave-path" x="50" y="3"></use>
        </g>
        <g className="wave2">
          <use xlinkHref="#wave-path" x="50" y="0"></use>
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9"></use>
        </g>
      </svg>
    </section>
  );
};

export default Hero;
