import React, { useState, useEffect } from 'react';
import LoaderComponenet from '../../LoaderComponenet';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import api from '../../Authenticaltion/api';
import './UserTimeTracker.css';

const UserTimeTracker = () => {
  const [useTimeTracker, SetUserTimeTracker] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [filterData, setFilterData] = useState({
    intime: '',
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    fetchuserTimeTracker();
  }, []);

  const columns = [
    {
      dataField: 'FormattedDate',
      text: 'Date',
      sort: true,
      headerStyle: { width: '15%' },
      formatter: (cell) => (
        <div className="table-cell">
          <i className="pi pi-calendar mr-2"></i>
          <span>{cell}</span>
        </div>
      )
    },
    {
      dataField: 'Emp_Id',
      text: 'Employee ID',
      sort: true,
      headerStyle: { width: '15%' },
      formatter: (cell) => (
        <div className="table-cell">
          <i className="pi pi-id-card mr-2"></i>
          <span>{cell}</span>
        </div>
      )
    },
    {
      dataField: 'Name',
      text: 'Employee Name',
      sort: true,
      headerStyle: { width: '25%' },
      formatter: (cell) => (
        <div className="table-cell">
          <i className="pi pi-user mr-2"></i>
          <span>{cell}</span>
        </div>
      )
    },
    {
      dataField: 'InTime',
      text: 'Clock In',
      headerStyle: { width: '20%' },
      formatter: (cell) => (
        <div className="table-cell">
          <i className="pi pi-sign-in mr-2"></i>
          <span>{cell}</span>
        </div>
      )
    },
    {
      dataField: 'OutTime',
      text: 'Clock Out',
      headerStyle: { width: '20%' },
      formatter: (cell) => (
        <div className="table-cell">
          <i className="pi pi-sign-out mr-2"></i>
          <span>{cell}</span>
        </div>
      )
    }
  ];

  const fetchuserTimeTracker = () => {
    setIsloading(true);
    api.get('/userTimeTracker')
      .then(res => {
        const DataName = res.data;
        SetUserTimeTracker(DataName);
      });
    setIsloading(false)
  }

  const handleFilter = () => {
    const filteredData = { ...filterData };
    setIsloading(true);
    if (filteredData.intime) {
      const intimeDate = new Date(filteredData.intime);
      const formattedIntime = `${intimeDate.getHours()}:${intimeDate.getMinutes()}:${intimeDate.getSeconds()}`;
      filteredData.intime = formattedIntime;
    }
    api
      .get('/userTimeTracker', {
        params: filteredData,
      })
      .then((res) => {
        console.log(res.data)
        SetUserTimeTracker(res.data);
        setFilterData({
          intime: '',
          startDate: '',
          endDate: '',
        })
        setIsloading(false)
        // Handle the response data as needed
      })
      .catch((error) => {
        setIsloading(false)
      });
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h2>User Time Tracker</h2>
      </div>

      <div className="dashboard-content">
        <div className="filter-panel">
          <div className="filter-header">
            <i className="pi pi-filter"></i>
            <h3>Filter Records</h3>
          </div>
          
          <div className="filter-controls">
            <div className="date-filters">
              <div className="filter-group">
                <label>
                  <i className="pi pi-calendar"></i>
                  Start Date
                </label>
                <Calendar
                  value={filterData.startDate}
                  onChange={(e) => setFilterData({ ...filterData, startDate: e.target.value })}
                  className="custom-calendar"
                  placeholder="Select start date"
                  dateFormat="dd/mm/yy"
                  showIcon
                />
              </div>

              <div className="filter-group">
                <label>
                  <i className="pi pi-calendar"></i>
                  End Date
                </label>
                <Calendar
                  value={filterData.endDate}
                  onChange={(e) => setFilterData({ ...filterData, endDate: e.target.value })}
                  className="custom-calendar"
                  placeholder="Select end date"
                  dateFormat="dd/mm/yy"
                  showIcon
                />
              </div>

              <div className="filter-group">
                <label>
                  <i className="pi pi-clock"></i>
                  Time
                </label>
                <Calendar
                  value={filterData.intime}
                  onChange={(e) => setFilterData({ ...filterData, intime: e.target.value })}
                  timeOnly
                  hourFormat="24"
                  className="custom-calendar"
                  placeholder="Select time"
                  showIcon
                />
              </div>
            </div>

            <Button
              label="Apply Filters"
              icon="pi pi-search"
              className="search-button"
              onClick={handleFilter}
              loading={isLoading}
            />
          </div>
        </div>

        <div className="data-table-section">
          {isLoading ? (
            <div className="loading-state">
              <LoaderComponenet />
              <span>Loading records...</span>
            </div>
          ) : (
            <div className="table-container">
              <BootstrapTable
                bootstrap4
                keyField="Emp_Id"
                data={useTimeTracker}
                columns={columns}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [
                    { text: '10', value: 10 },
                    { text: '25', value: 25 },
                    { text: '50', value: 50 }
                  ],
                  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                    <div className="pagination-size-selector">
                      <span>Show</span>
                      {options.map((option) => (
                        <button
                          key={option.text}
                          onClick={() => onSizePerPageChange(option.page)}
                          className={`size-button ${currSizePerPage === `${option.page}` ? 'active' : ''}`}
                        >
                          {option.text}
                        </button>
                      ))}
                      <span>entries</span>
                    </div>
                  )
                })}
                noDataIndication={() => (
                  <div className="no-data-message">
                    <i className="pi pi-inbox"></i>
                    <h3>No Records Found</h3>
                    <p>Try adjusting your filters or add new time records</p>
                  </div>
                )}
                classes="modern-data-table"
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserTimeTracker;
