import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import api from '../../../Authenticaltion/api';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { MultiSelect } from 'primereact/multiselect';
// import { useLoading } from "../../LoadingContext";
import { useLoading } from "../../../LoadingContext";
import '../Leaves.css'
import { toast } from 'react-toastify';
const HandleAssignedAdminLeave = () => {
  const [AssignedLeave, SetAssignedLeave] = useState([]);
  const { updateLoading } = useLoading()
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filterData, setFilterData] = useState({
    name: null,
    startDate: null,
    toDate: null,
    leaveType: null,
    status: null,
  });
  const calculateDays = () => {
    if (selectedLeave?.Days && selectedLeave?.Duration) {
      const adjustedDuration = (selectedLeave.Duration === 0.5 || selectedLeave.Duration === 0.6) ? 0.5 : selectedLeave.Duration;
        const totalDays = selectedLeave.Days * adjustedDuration;
        return totalDays // Adjust this logic based on the specific requirement
    }
    return 0; // Default value if data is not available
};
const [employee, setEmployee] = useState([]);
  const [leaveTypes, setLeavesTypes] = useState([])
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);

  const fetchLeaveTypes = async () => {
    updateLoading(true);
    try {
      const response = await api.get('/leaveTypeAndBalanceValue');
      if (response.status === 200) {
        console.log("Leave Type is :", response.data);
        setLeaveTypeOptions(response.data);
      } else {
        toast.error('Failed to fetch leave types', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500
        });
      }
    } catch (error) {
      console.error('Error fetching leave types:', error);
      toast.error('Error fetching leave types', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500
      });
    } finally {
      updateLoading(false);
    }
  };

  const fetchUsers = async () => {
    updateLoading(true);
    try {
      const response = await api.get('/admin-employee');
      if (response.status === 200) {
        const sortedEmployees = response.data.data.sort((a, b) => a.Name.localeCompare(b.Name));
        setEmployee(sortedEmployees);
      } else {
        toast.error('Failed to fetch users', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500
        });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Error fetching users', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500
      });
    } finally {
      updateLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchLeaveTypes();
  }, []);

  const getFilteredLeaves = () => {
    if (!selectedUserId) return AssignedLeave;
    return AssignedLeave.filter(leave => leave.uniqueUserId === selectedUserId);
  };

  const [filters, setFilters] = useState({
    userIds: [],
    leave_id: null,
    status: null,
    leave_type: null
  });

  const handleUserSelection = (e) => {
    const selectedUserId = e.value;
    setFilters(prev => ({
      ...prev,
      userIds: selectedUserId ? [selectedUserId] : []
    }));
  };

  const fetchAssignedLeaves = async () => {
    updateLoading(true)
    try {
      const response = await api.get('/adminShowLeaves', {
        params: {
          page: 1,
          pageSize: 36,
          filters
        },
      });
      const data = response.data;
      console.log("all_assigned", data)
      SetAssignedLeave(data);
    } catch (error) {
      console.error('Error fetching assigned leaves:', error);
      toast.error('Error fetching assigned leaves', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500
      });
    }
    finally {
      updateLoading(false)
    }
  };

  useEffect(() => {
    if (filters.userIds.length > 0) {
      fetchAssignedLeaves();
    } else {
      // If no users selected, fetch all leaves
      fetchAssignedLeaves();
    }
  }, [filters]);

  const handleViewDetails = async (leaveId) => { 
    updateLoading(true);
    try {
      const Result = await api.get(`/getAssignedLeaveInfo/${leaveId}`)
      if (Result.data && Result.data[0]) {
        setSelectedLeave(Result.data[0]);
        setShowDialog(true);
      } else {
        toast.error('Leave details not found', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500
        });
      }
    } catch (error) {
      console.error('Error fetching leave details:', error);
      toast.error('Error fetching leave details', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500
      });
    } finally {
      updateLoading(false);
    }
  };

  const isClearButtonDisabled =
  !filters.userIds.length && !filters.leave_id && !filters.leave_type && !filters.status;
  const [isLoading, SetisLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [dialogInput, setDialogInput] = useState(null);
  const [showInputDialog, setShowInputDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false); // State for showing the dialog
  const [selectedLeave, setSelectedLeave] = useState(null); // State to store the selected leave
  const [showDocument, setShowDocument] = useState(false);
  const [page, setPage] = useState(1); // Start from page 1
  const pageSize = 36; // Default to 50 items per page
  const [hasMore, setHasMore] = useState(true); // To check if there's more data to fetch
  const handleFilter = () => {
    console.log('searching taken');

    fetchAssignedLeaves();
  };
  const handleCloseDialog = () => {
    setSelectedAction('');
    setShowDialog(false);
    setDialogInput('');
    setShowInputDialog(false); // Ensure the input dialog is also closed // Reset input field if needed
  };
  const handleLeaveAction = (action) => {
    if (action === 'Approved' || action === 'Rejected') {
      setDialogInput('');
      // Open the input dialog
      setShowInputDialog(true);
      setSelectedAction(action);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && (newPage !== page || hasMore)) {
        setPage(newPage);
    }
};

  const handleDialogSubmit = async () => {
    setShowInputDialog(false);
    setShowDialog(false);
    updateLoading(true)
    console.log("Printing Selected Leave", selectedLeave);
    console.log("Printing LeaveOperation", selectedAction);
    const formLeaveData = await {
      Operation: selectedAction,
      Leaveid: selectedLeave.id,
      message: dialogInput,
      StartDate: selectedLeave.StartDate,
      EndDate: selectedLeave.EndDate,
      Duration: selectedLeave.Duration,
      LeaveType: selectedLeave.LeaveType,
      LeaveTypeId: selectedLeave.LeaveTypeId,
      userId: selectedLeave.uniqueEmployeeId,
      Days: selectedLeave.Days
    };

    try {
      const response = await api.post('/AdminLeaveApproved', formLeaveData);
      console.log(response);

      // Check the status code from the response
      if (response.status === 200) {
        // Assuming the backend sends a JSON response with a "message" field
        const responseData = response.data;

        if (responseData.message === 'Leave approved successfully') {
          // Update your state or perform other actions for approval success
          toast.success('Leave was approved successfully.');
        } else if (responseData.message === 'Leave rejected successfully') {
          // Update your state or perform other actions for rejection success
          toast.success('Leave was rejected successfully.');
        } else if (responseData.message === 'Leave deleted successfully') {
          // Update your state or perform other actions for delete success
          toast.success('Leave was deleted successfully.');
        } else {
          toast.success(responseData.message)
          // Handle unexpected success response here
        }
      } else {
        // Handle errors or other status codes.
        toast.error('Leave operation failed:', response.statusText);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`An error occurred: ${error.response.data.error}`);
    } else {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
    }
    } finally {
      // After handling the response or error, you can set isLoading to false.
      setSelectedAction('');
      setDialogInput('');
      fetchAssignedLeaves();
      updateLoading(false)
    }

  };

  const clearFilters = () => {
    setSelectedUserId(null);
    setFilters({
      userIds: [],
      leave_id: null,
      status: null,
      leave_type: null
    });
  };

  return (
    <>
      <div className="shadow p-4 bg-white rounded" style={{ 
        minHeight: '85vh',
        background: 'linear-gradient(to bottom right, #ffffff, #f8f9fa)'
      }}>
        {/* Filter Section */}
        <div className="filter-section mb-4 p-3" style={{
          background: 'white',
          marginTop: '35px',
          borderRadius: '12px',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
        }}>
          <h5 className="mb-3 text-primary" style={{ fontWeight: '600' }}>Filter Leaves</h5>
          <div className="row g-3">
            <div className="col-md-3 mb-2">
              <MultiSelect
                value={filters.userIds}
                options={employee}
                onChange={(e) => setFilters({ ...filters, userIds: e.value })}
                optionLabel="Name"
                optionValue="id"
                placeholder="Select Users"
                className="multiselect-custom"
                display="chip"
                filter={true}  // Enable filtering
                filterBy="Name" // Filter by the Name property
                filterPlaceholder="Search users..." // Add placeholder for search
                filterMatchMode="contains" // Match anywhere in the text
                showClear={true} // Show clear button
                style={{
                  background: 'white',
                  maxHeight: '40px',
                  overflowY: 'auto',
                  whiteSpace: 'nowrap',
                  fontSize: '12px',
                  fontWeight: 'normal'
                }}
                panelStyle={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
                virtualScrollerOptions={{ itemSize: 43 }} // For better performance with large lists
              />
            </div>
            <div className='col-md-3 mb-2'>
              <Dropdown
                value={filters.status}
                options={[
                  { label: 'Pending', value: 'Pending' },
                  { label: 'Approved', value: 'Approved' },
                  { label: 'Rejected', value: 'Rejected' },   
                ]}
                onChange={(e) => setFilters({ ...filters, status: e.value })}
                placeholder="Select Status"
                style={{
                  background: 'white',
                  maxHeight: '40px',
                  overflowY: 'auto',
                  fontSize: '12px',
                  width: '100%',
                
                }}
                panelStyle={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              />
            </div>
            <div className="col-md-3 mb-2 d-flex justify-content-center align-items-center">
              <button className="btn btn-danger btn-sm form-label" style={{cursor:'pointer'}} onClick={()=>{
                clearFilters()
            
              }}
              disabled={isClearButtonDisabled}  >Clear</button>
            </div>
          </div>
        </div>

        {/* Leave Cards Container */}
        <div className="leave-cards-container">
          <div className="row g-2">
            {getFilteredLeaves().length > 0 ? (
              getFilteredLeaves().map((leave, index) => (
                <div key={index} className="col-md-4 col-lg-3">
                  <div className="card h-100 border-0 shadow-sm" 
                    style={{ 
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                      borderRadius: '12px',
                      overflow: 'hidden'
                    }}
                    onClick={() => handleViewDetails(leave.id)}
                  >
                    <div className='card-header border-0 py-3' style={{ background: '#5B8FFF' }}>
                      <h6 className='text-white mb-0 text-center fw-bold'>{leave.LeaveType}</h6>
                    </div>
                    <div className='card-body p-3'>
                      <div className=''>
                        <small className='text-muted'>Leave Sub Type</small>
                        <div className='fw-bold'>{leave.LeaveSubType || "Not Available"}</div>
                      </div>
                      <div className=''>
                        <small className='text-muted'>Employee</small>
                        <div className='fw-bold'>
                          <i className="fa fa-user me-2 text-primary"></i>
                          {leave.EmployeeName}
                        </div>
                      </div>
                      <div className=''>
                        <div className=''>
                          <small className='text-muted'>Duration</small>
                          <div className='fw-bold'>
                            <i className="fa fa-clock me-2 text-primary"></i>
                            {leave.Duration === 0.5 ? "First Half" : 
                             leave.Duration === 0.6 ? "Second Half" : 
                             leave.Duration === 1.0 ? "Full Day" : ""}
                          </div>
                        </div>
                        <div className=''>
                          <small className='text-muted'>From</small>
                          <div className='fw-bold'>
                            <i className="fa fa-calendar me-2 text-primary"></i>
                            {leave.StartDate}
                          </div>
                        </div>
                        <div className=''>
                          <small className='text-muted'>To</small>
                          <div className='fw-bold'>
                            <i className="fa fa-calendar me-2 text-primary"></i>
                            {leave.EndDate}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer border-0 p-0'>
                      <div className={`text-center p-2 ${
                        leave.finalstatus === 'Pending' ? 'bg-warning' : 
                        leave.finalstatus === 'Approved' ? 'bg-success' : 
                        'bg-danger'}`}
                      >
                        <span className='status text-white fw-bold'>{leave.finalstatus}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center py-5">
                <div className="empty-state">
                  <i className="fas fa-inbox mb-3" style={{ fontSize: '3rem', color: '#ccc' }}></i>
                  <h5 className="text-muted">No Leaves Found</h5>
                  <p className="text-muted">No assigned leaves are available at the moment.</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pagination-controls" style={{ display: 'flex', justifyContent: 'end' }}>
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className='btn btn-primary'
          >
            Previous
          </button>
          <span>Page {page}</span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={!hasMore}
            className='btn btn-primary'
          >
            Next
          </button>
        </div>
      </div>
      <Dialog 
        open={showDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ bgcolor: '#5B8FFF', color: 'white', p: 2, m: 0 }}>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='text-white'>
              <span className='h-100 fw-bold' style={{ fontSize: '20px' }}>
                {selectedLeave?.LeaveType} - {selectedLeave?.Duration === 0.5 ? "First Half" : selectedLeave?.Duration === 0.6 ? "Second Half" : selectedLeave?.Duration === 1.0 ? "Full Day" : `${selectedLeave?.Duration} ${selectedLeave?.subType}`} {selectedLeave?.LeaveSubType}
              </span>
            </div>
            <IconButton
              edge="end"
              onClick={handleCloseDialog}
              aria-label="close"
              sx={{ color: 'white' }}
            >
              <i className="fa fa-times" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: '#D7D9DE', p: 3 }}>
          <div className='card chat w-100' style={{ padding: '0', margin: '0' }}>
            <div className='card-body max-w-60'>
              <div className='row'>
                <div>
                  <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                    Status :
                    <span style={{ fontSize: '16px', fontWeight: '700' }} className={`status-text ${selectedLeave?.finalstatus === 'Approved' ? 'text-success' : selectedLeave?.finalstatus === 'Rejected' ? 'text-danger' : 'text-warning'}`}>
                      {selectedLeave?.finalstatus}
                    </span>
                  </span>
                  <div className="mt-2">
                    <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                      Available Leave Balance:
                    </span>
                    <span style={{ color: '#000', fontWeight: '700', fontSize: '16px', marginLeft: '5px' }}>
                      {selectedLeave?.remaining_balance || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='mt-2'>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-suitcase' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>Leave is of <strong>{calculateDays()}</strong> {calculateDays() <= 1 ? "day" : "days"}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-calendar' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>From: {selectedLeave?.StartDate}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-calendar' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>To: {selectedLeave?.EndDate}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-file' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>
                      Attached Document: {selectedLeave?.AttachedDocumnet.FilePathName ? (
                        <a
                          href={selectedLeave.AttachedDocumnet.FilePathName}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(selectedLeave.AttachedDocumnet.FilePathName, '_blank');
                          }}
                          role="button"
                          target='_blank'
                          aria-label={`Download ${selectedLeave.AttachedDocumnet.originalFilename}`}
                          style={{ color: '#6366F1', fontWeight: 'bold' }}
                        >
                          {selectedLeave.AttachedDocumnet.originalFilename}
                        </a>
                      ) : (
                        <span>Not provided</span>
                      )}
                    </h6>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="mt-4 ml-2" style={{ color: '#021317', fontWeight: "700" }}>Leave History</h5>
              </div>
              <div className="card chat-history mt-2" style={{ backgroundColor: '#F9FCFF' }}>
                <div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="d-flex flex-column mt-3 ml-1">
                        <div className="d-flex align-items-center ml-3" style={{ fontSize: "12px", color: "#6366F1" }}>
                          <strong >{selectedLeave?.user_history[0].UpdatedUserName}</strong>
                          <div className="message-data-time ml-2">
                            {selectedLeave?.user_history[0].DateChanged}
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div>
                            <img
                              src={selectedLeave?.user_history[0].UpdatedUserimage || `/images/icon/Noprofile.webp`}
                              className="rounded-circle mr-2"
                              style={{ width: "60px", height: "50px", objectFit: "cover" }}
                              alt="avatar"
                            />
                          </div>
                          <div
                            className="card message other-message p-2 mr-5 ml-1"
                            style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                            dangerouslySetInnerHTML={{ __html: selectedLeave?.user_history[0].Message }}
                          />
                        </div>
                      </div>
                    </li>
                    {selectedLeave?.user_history.map((historyItem, index) => (
                      index >= 1 && (
                        <li className="clearfix mt-2 d-flex justify-content-end ml-auto mr-1" key={index}>
                          <div className="d-flex flex-column align-items-end mt-3">
                            <div className="d-flex align-items-center mr-3" style={{ fontSize: "12px", color: "#6366F1" }}>
                              <div className="message-data-time mr-2">
                                {historyItem.DateChanged}
                              </div>
                              <strong>{historyItem.UpdatedUserName}</strong>
                            </div>
                            <div className="d-flex mt-1 mr-3">
                              <div
                                className="card message other-message p-2"
                                style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                                dangerouslySetInnerHTML={{ __html: historyItem.Message }}
                              />
                              <div>
                                <img
                                  src={historyItem.UpdatedUserimage || `/images/icon/Noprofile.webp`}
                                  className="rounded-circle ml-2"
                                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                  alt="avatar"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                marginRight: "8px",
                                fontSize: "12px",
                                fontWeight: "600",
                                color: historyItem.Status === "Rejected" ? "red" : "green"
                              }}
                            >
                              {historyItem.Status}
                            </div>
                          </div>
                        </li>
                      )
                    ))}
                  </ul>
                </div>
              </div>
              {selectedLeave?.finalstatus == "Pending" && (
                <div>
                  <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-success me-2" onClick={() => handleLeaveAction('Approved', selectedLeave)}>Approve</button>
                    <button type="button" className="btn btn-danger" onClick={() => handleLeaveAction('Rejected', selectedLeave)}>Reject</button>
                  </div>

                  {selectedAction && (
                    <div className="card mt-2 p-2">
                      <textarea
                        className="form-control mt-2"
                        value={dialogInput}
                        placeholder={selectedAction==="Approved"?"Reason for Approval":"Reason for Rejection"}
                        onChange={(e) => setDialogInput(e.target.value)}
                        style={{ height: '100px' }}
                      />
                      <div className="d-flex justify-content-center mt-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleDialogSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Input Dialog for Approve/Reject */}
      <Dialog
        open={showInputDialog}
        onClose={() => setShowInputDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ bgcolor: '#5B8FFF', color: 'white', p: 2 }}>
          <div className='d-flex justify-content-between align-items-center'>
            <span>{selectedAction === 'Approved' ? 'Approve Leave' : 'Reject Leave'}</span>
            <IconButton
              edge="end"
              onClick={() => setShowInputDialog(false)}
              aria-label="close"
              sx={{ color: 'white' }}
            >
              <i className="fa fa-times" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <textarea
            className="form-control mt-2"
            value={dialogInput}
            placeholder={selectedAction === "Approved" ? "Reason for Approval" : "Reason for Rejection"}
            onChange={(e) => setDialogInput(e.target.value)}
            style={{ height: '100px' }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDialogSubmit}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default HandleAssignedAdminLeave;
