import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRegistered } from '@fortawesome/free-regular-svg-icons';
import useAuth from './Authenticaltion/roleFinder';
import './MobileSidebar.css';

const MobileHeader = () => {
    const location = useLocation();
    const { role } = useAuth(); // Fetch role from useAuth hook

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeRoute, setActiveRoute] = useState(location.pathname);

    const [activeSection, setActiveSection] = useState(role === 'MASTERADMIN' ? 'admin' : 'basic');

    const handleSectionClick = (section) => {
        setActiveSection(prevSection => (prevSection === section ? '' : section));
    };

    const handleRouteClick = (path) => {
        setActiveRoute(path);
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        setActiveRoute(location.pathname);
    }, [location.pathname]);

    // Define your routes here (adminRoutes, superuserRoutes, etc.)
    const adminRoutes = [
        {
            path: "/Dashboard",
            name: "Dashboard",
            logo: 'fas fa-home'
        },
        {
            path: '/EmployeeList',
            name: "Employee Details",
            logo: "fas fa-user-plus",
        },
        {
            path: '/TimeTracker',
            name: "Time Tracker",
            logo: 'fas fa-stopwatch',
        },
        { path: '/Clockify_Report', name: "Clockify-time-report", logo: 'fas fa-stopwatch' },
        {
            path: '/UploadFile',
            name: "Upload File",
            logo: 'fas fa-upload',
        },
        {
            path: '/Holidays',
            name: "Holidays",
            logo: 'fas fa-calendar-alt',
        },
        {
            path: '/Manage-Holidays',
            name: "Manage Holidays",
            logo: 'fas fa-cogs',
        },
        {
            path: '/ViewAllLeave',
            name: 'User Leaves',
            logo: "fas fa-check-circle"
        },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' },
       
    ];

    const superuserRoutes = [
        {
            path: "/Dashboard",
            name: "Dashboard",
            logo: 'fas fa-home'
        },
        {
            path: '/EmployeeList',
            name: "Employee Details",
            logo: "fas fa-user-plus",
        },
        {
            path: '/TimeTracker',
            name: "Time Tracker",
            logo: 'fas fa-stopwatch',
        },
        { path: '/Clockify_Report', name: "Clockify-time-report", logo: 'fas fa-stopwatch' },
        {
            path: '/UploadFile',
            name: "Upload File",
            logo: 'fas fa-upload',
        },
        {
            path: '/Holidays',
            name: "Holidays",
            logo: 'fas fa-calendar-alt',
        },
        {
            path: '/Manage-Holidays',
            name: "Manage Holidays",
            logo: 'fas fa-cogs',
        },
        {
            path: '/ViewAllLeave',
            name: 'User Leaves',
            logo: "fas fa-check-circle"
        },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' },
        
    ];

    const masterAdminRoutes = [
        {
            path: "/Dashboard",
            name: "Dashboard",
            logo: 'fas fa-home'
        },
        {
            path: '/EmployeeList',
            name: "Employee Details",
            logo: "fas fa-user-plus",
        },
        {
            path: '/TimeTracker',
            name: "Time Tracker",
            logo: 'fas fa-stopwatch',
        },
        { path: '/Clockify_Report', name: "Clockify-time-report", logo: 'fas fa-stopwatch' },
        {
            path: '/Holidays',
            name: "Holidays",
            logo: 'far fa-calendar-alt',
        },
        {
            path: '/Manage-Holidays',
            name: "Manage Holidays",
            logo: 'fas fa-cogs',
        },
        {
            path: '/UploadFile',
            name: 'Upload File',
            logo: 'fas fa-upload',
        },
        {
            path: '/ViewAllLeave',
            name: 'User leaves',
            logo: 'fas fa-check'
        },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' },
       
    ];

    const AddRoutes = [
        {
            path: '/User-Leaves',
            name: 'Verify Leaves',
            logo: "fas fa-check-circle"
        },
    ];

    // const basicRoutes = [
    //     {
    //         path: "/UserDashboard",
    //         name: "Dashboard",
    //         logo: 'fas fa-home'
    //     },
    //     {
    //         path: '/Leaves',
    //         name: "My Leaves",
    //         logo: 'fas fa-bed',
    //     },
    //     {
    //         path: '/user-TimeTracker',
    //         name: 'Time Tracker',
    //         logo: 'fas fa-stopwatch',
    //     },
    //     {
    //         path: '/Holidays',
    //         name: "Holidays",
    //         logo: 'far fa-calendar-alt',
    //     },
    // ];
    const basicRoutes = [
        { path: "/UserDashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/Leaves', name: "My Leaves", logo: 'fas fa-bed' },
        { path: '/user-TimeTracker', name: 'Time Tracker', logo: 'fas fa-stopwatch' },
        { path: '/Reimbursement', name: 'Reimbursement', logo: 'fas fa-rupee', },
        { path: '/Holidays', name: "Holidays", logo: 'far fa-calendar-alt' },
        { path: '/SalarySlips', name: "Salary Slips", logo: 'fas fa-file-alt' },
        { path: '/Resignation', name: 'Resignation', logo: <FontAwesomeIcon icon={faRegistered} style={{color: "black",padding:"5px"}} /> }

    ];
    const renderSection = (sectionName, routes) => (
        <>
            <li
                className="w-100 d-flex justify-content-center"
                onClick={() => handleSectionClick(sectionName)}
                style={{ cursor: 'pointer', borderRadius: 'none' }}
            >
                {sectionName === 'admin' ? 'Admin Features' : 'My Features'}
            </li>
            <hr style={{ color: '#0D6EFD' }} />
            <div className={`section ${activeSection === sectionName ? 'open' : ''}`}>
                {routes.map((route) => (
                    <li
                        className={`menu-item ${activeRoute === route.path ? 'active sidebar_active' : 'sidebar_deactive'}`}
                        key={route.name}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleRouteClick(route.path)}
                    >
                        <NavLink
                            to={route.path}
                            style={{
                                color: activeRoute === route.path ? 'white' : 'inherit',
                                textDecoration: 'none',
                                fontSize: '13px',
                                fontWeight: activeRoute === route.path ? 'bold' : 'normal'
                            }}
                        >
                            <i className={route.logo} style={{ width: '2px' }} />
                            <span> {route.name}</span>
                        </NavLink>
                    </li>
                ))}
            </div>
        </>
    );

    const getRoutesByRole = (role) => {
        switch (role) {
            case 'ADMIN':
                return adminRoutes;
            case 'SUPERADMIN':
                return superuserRoutes;
            case 'MASTERADMIN':
                return masterAdminRoutes;
            case 'USER':
            case 'MANAGER':
                return basicRoutes.concat(AddRoutes);
            default:
                return [];
        }
    };

    const userRoutesToShow = getRoutesByRole(role);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <header className="header-mobile d-block d-lg-none">
                <div className="header-mobile__bar">
                    <div className="container-fluid">
                        <div className="header-mobile-inner">
                            <Link className="logo " href="index.html">
                                <img src="/images/LogoSpc.png" alt="WFMS" style={{ height: '50px' }} />
                            </Link>
                            <button
                                className="hamburger hamburger--slider"
                                type="button"
                                onClick={handleDropdownToggle}
                            >
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                {isDropdownOpen && (
                    <nav className="navbar-mobile" style={{ display: 'block', marginLeft: "-20px" }}>
                        <div className="container-fluid">
                            <ul className="navbar-mobile__list list-unstyled">
                                {/* Render admin and basic sections */}
                                {renderSection('admin', adminRoutes)}
                                {renderSection('basic', basicRoutes)}
                            </ul>
                        </div>
                    </nav>
                )}
            </header>
        </>
    );
};

export default MobileHeader;
