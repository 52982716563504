import React, { useEffect, useState } from 'react';
import api from '../../Authenticaltion/api';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { useLoading } from "../../LoadingContext";
import './Leaves.css'
import { toast } from 'react-toastify';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown'

const AssignedLeaves = () => {
  const [AssignedLeave, SetAssignedLeave] = useState([]);
  const { updateLoading } = useLoading()
  const [employee, setEmployee] = useState([]);
  const [leaveTypes, setLeavesTypes] = useState([])

  const [filters, setFilters] = useState({ 
    userIds: [],
    leave_id: null,
    status: null,
    leave_type: null
  });
  const [isLoading, SetisLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [dialogInput, setDialogInput] = useState(null);
  const [showInputDialog, setShowInputDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false); // State for showing the dialog
  const [selectedLeave, setSelectedLeave] = useState(null); // State to store the selected leave
  const [showDocument, setShowDocument] = useState(false);
  const [page, setPage] = useState(1); // Start from page 1
  const pageSize = 36; // Default to 50 items per page
  const [hasMore, setHasMore] = useState(true); // To check if there's more data to fetch
  const handleFilter = () => {
    console.log('searching taken');

    fetchAssignedLeaves();
  };
  useEffect(() => {
    setPage(1)
    fetchAssignedLeaves()
  }, [filters])
  
  const calculateDays = () => {
    console.log("Selected Leave", selectedLeave)
    if (selectedLeave?.Days && selectedLeave?.Duration) {
      const adjustedDuration = (selectedLeave.Duration === 0.5 || selectedLeave.Duration === 0.6) ? 0.5 : selectedLeave.Duration;
      const totalDays = selectedLeave.Days * adjustedDuration;
      return totalDays // Adjust this logic based on the specific requirement
    }
    return 0; // Default value if data is not available
  };
  const fetchAssignedLeaves = async () => {
    updateLoading(true)
    try {
      const response = await api.get('/getAllAssignedLeaves', {
        params: {
          page,
          pageSize,
          filters
        },
      });
      const data = response.data;
      console.log("all_asigned", data)
      SetAssignedLeave(data);
      if (data.length < pageSize) {
        setHasMore(false);
      } else {
        setHasMore(true);
      } // Update state with the fetched data
    } catch (error) {
      console.error('Error fetching assigned leaves:', error);
    }
    finally {
      updateLoading(false)
    }
  };
  const fetchLeavesType = async (req, res) => {
    try {
      const response = await api.get('/leaveTypeAndBalanceValue'); // Adjust the API endpoint as per your backend
      if (response.status === 200) {
        console.log("Leave Type is :", response.data);
        setLeavesTypes(response.data)
        // setLeavesType(response.data); // Assuming the response contains an array of leave types
      }
    }
    catch (err) {
      console.log("Error")
    }

  }
  const fetchEmployee = async () => {
    try {
      const response = await api.get('/ManagerBasedEmployeeId');
      console.log("Pringing trsilne", response.data)
      setEmployee(response.data);
    } catch (error) {
      console.error('Error fetching employee list', error);
    }
  }
  useEffect(() => {
    fetchAssignedLeaves();
  }, [page]);
  const handleViewDetails = async (leaveId) => { 
    const Result = await api.get(`/getAssignedLeaveInfo/${leaveId}`)
    setSelectedLeave(Result.data[0]);
    setShowDialog(true);
  };
  const handleCloseDialog = () => {
    setSelectedAction('');
    setDialogInput('');
    setShowDialog(false);
    setShowInputDialog(false); // Ensure the input dialog is also closed // Reset input field if needed
  };
  const handleLeaveAction = (action) => {
    if (action === 'Approved' || action === 'Rejected') {
      // Open the input dialog
      setDialogInput('');
      setShowInputDialog(true);
      setSelectedAction(action);
    }
  };
  useEffect(() => {
    fetchEmployee()
    fetchLeavesType()
  }, [])

  const handleDialogSubmit = async () => {
    setShowInputDialog(false);
    setShowDialog(false);
    updateLoading(true)
    console.log("Printing Selected Leave", selectedLeave);
    console.log("Printing LeaveOperation", selectedAction);
    const formLeaveData = await {
      Operation: selectedAction,
      Leaveid: selectedLeave.id,
      message: dialogInput,
      StartDate: selectedLeave.StartDate,
      EndDate: selectedLeave.EndDate,
      Duration: selectedLeave.Duration,
      LeaveType: selectedLeave.LeaveType,
      LeaveTypeId: selectedLeave.LeaveTypeId,
      userId: selectedLeave.uniqueEmployeeId,
      Days: selectedLeave.Days
    };

    try {
      const response = await api.post('/LeaveOperation', formLeaveData);
      console.log(response);

      // Check the status code from the response
      if (response.status === 200) {
        // Assuming the backend sends a JSON response with a "message" field
        const responseData = response.data;

        if (responseData.message === 'Leave approved successfully') {
          // Update your state or perform other actions for approval success
          toast.success('Leave was approved successfully.');
        } else if (responseData.message === 'Leave rejected successfully') {
          // Update your state or perform other actions for rejection success
          toast.success('Leave was rejected successfully.');
        } else if (responseData.message === 'Leave deleted successfully') {
          // Update your state or perform other actions for delete success
          toast.success('Leave was deleted successfully.');
        } else {
          toast.success(responseData.message)
          // Handle unexpected success response here
        }
      } else {
        // Handle errors or other status codes.
        toast.error('Leave operation failed:', response.statusText);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`An error occurred: ${error.response.data.error}`);
      } else {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
      }
    } finally {
      // After handling the response or error, you can set isLoading to false.
      setSelectedAction('');
      setDialogInput('');
      fetchAssignedLeaves();
      updateLoading(false)
    }

  };
  const handlePageChange = (newPage) => {
    if (newPage > 0 && (newPage !== page || hasMore)) {
      setPage(newPage);
    }
  };
  const clearFilters = () => {
    setFilters({
      userIds: [],
      leave_id: null,
      status: null,
      leave_type: null
    });
  };
  const isClearButtonDisabled =
  !filters.userIds.length && !filters.leave_id && !filters.leave_type && !filters.status;
  
  return (
    <>
      <div className="shadow p-4 bg-white rounded" style={{ 
        minHeight: '85vh',
        background: 'linear-gradient(to bottom right, #ffffff, #f8f9fa)'
      }}>
        {/* Filter Section */}
        <div className="filter-section mb-4 p-3" style={{
          background: 'white',
          marginTop: '35px',
          borderRadius: '12px',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
        }}>
          <h5 className="mb-3 text-primary" style={{ fontWeight: '600' }}>Filter Leaves</h5>
          <div className="row g-3">
            <div className="col-md-3">
              <label className="form-label mb-2" style={{ fontSize: '14px', color: '#666' }}>Select Users</label>
              <MultiSelect
                value={filters.userIds}
                options={employee}
                onChange={(e) => setFilters({ ...filters, userIds: e.value })}
                optionLabel="Name"
                optionValue="id"
                placeholder="Select Users"
                className="multiselect-custom"
                display="chip"
                filter={false}
                filterBy="name"
                style={{
                  background: 'white',
                  maxHeight: '40px',
                  borderRadius: '8px',
                  border: '1px solid #e0e0e0',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    borderColor: '#5B8FFF'
                  }
                }}
                panelStyle={{
                  maxHeight: '250px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 16px rgba(0,0,0,0.1)'
                }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label mb-2" style={{ fontSize: '14px', color: '#666' }}>Leave Type</label>
              <Dropdown
                value={filters.leave_id}
                options={leaveTypes}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    leave_id: e.value,
                    leave_type: leaveTypes.find((type) => type.leave_id === e.value)?.leave_type,
                  })
                }
                optionLabel="leave_type"
                optionValue="leave_id"
                placeholder="Select Leave Type"
                style={{
                  background: 'white',
                  maxHeight: '40px',
                  borderRadius: '8px',
                  border: '1px solid #e0e0e0',
                  transition: 'all 0.2s ease',
                  width: '100%',
                  '&:hover': {
                    borderColor: '#5B8FFF'
                  }
                }}
                panelStyle={{
                  maxHeight: '250px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 16px rgba(0,0,0,0.1)'
                }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label mb-2" style={{ fontSize: '14px', color: '#666' }}>Status</label>
              <Dropdown
                value={filters.status}
                options={[
                  { label: 'Pending', value: 'Pending' },
                  { label: 'Approved', value: 'Approved' },
                  { label: 'Rejected', value: 'Rejected' },
                ]}
                onChange={(e) => setFilters({ ...filters, status: e.value })}
                placeholder="Select Status"
                style={{
                  background: 'white',
                  maxHeight: '40px',
                  borderRadius: '8px',
                  border: '1px solid #e0e0e0',
                  transition: 'all 0.2s ease',
                  width: '100%',
                  '&:hover': {
                    borderColor: '#5B8FFF'
                  }
                }}
                panelStyle={{
                  maxHeight: '200px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 16px rgba(0,0,0,0.1)'
                }}
              />
            </div>
            <div className="col-md-3 d-flex align-items-end">
              <button 
                className="btn btn-danger w-100" 
                style={{
                  borderRadius: '8px',
                  padding: '8px 16px',
                  transition: 'all 0.2s ease',
                  opacity: isClearButtonDisabled ? 0.6 : 1,
                  cursor: isClearButtonDisabled ? 'not-allowed' : 'pointer'
                }} 
                onClick={clearFilters}
                disabled={isClearButtonDisabled}
              >
                <i className="fas fa-times-circle me-2"></i>
                Clear Filters
              </button>
            </div>
          </div>
        </div>

        {/* Leaves Grid */}
        <div className="leaves-grid mt-4">
          <div className="row g-4">
            {AssignedLeave.length > 0 ? (
              AssignedLeave.map((leave) => (
                <div className="col-xl-3 col-sm-6" key={leave.id}>
                  <div 
                    className='card leave-card h-100' 
                    onClick={() => handleViewDetails(leave.id)}
                    style={{ 
                      cursor: 'pointer',
                      borderRadius: '12px',
                      background: 'white',
                      boxShadow: '0 4px 16px rgba(0,0,0,0.08)',
                      transition: 'all 0.3s ease',
                      border: 'none',
                      overflow: 'hidden',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 8px 24px rgba(0,0,0,0.12)'
                      }
                    }}
                  >
                    <div className='card-header border-0 py-3' style={{ background: '#5B8FFF' }}>
                      <h6 className='text-white mb-0 text-center fw-bold'>{leave.LeaveType}</h6>
                    </div>
                    <div className='card-body p-3'>
                      <div className=''>
                        <small className='text-muted'>Leave Sub Type</small>
                        <div className='fw-bold'>{leave.LeaveSubType || "Not Available"}</div>
                      </div>
                      <div className=''>
                        <small className='text-muted'>Employee</small>
                        <div className='fw-bold'>
                          <i className="fa fa-user me-2 text-primary"></i>
                          {leave.EmployeeName}
                        </div>
                      </div>
                      <div className=''>
                        <div className=''>
                          <small className='text-muted'>Duration</small>
                          <div className='fw-bold'>
                            <i className="fa fa-clock me-2 text-primary"></i>
                            {leave.Duration === 0.5 ? "First Half" : 
                             leave.Duration === 0.6 ? "Second Half" : 
                             leave.Duration === 1.0 ? "Full Day" : ""}
                          </div>
                        </div>
                        <div className=''>
                          <small className='text-muted'>From</small>
                          <div className='fw-bold'>
                            <i className="fa fa-calendar me-2 text-primary"></i>
                            {leave.StartDate}
                          </div>
                        </div>
                        <div className=''>
                          <small className='text-muted'>To</small>
                          <div className='fw-bold'>
                            <i className="fa fa-calendar me-2 text-primary"></i>
                            {leave.EndDate}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer border-0 p-0'>
                      <div className={`text-center p-2 ${
                        leave.finalstatus === 'Pending' ? 'bg-warning' : 
                        leave.finalstatus === 'Approved' ? 'bg-success' : 
                        'bg-danger'}`}
                      >
                        <span className='status text-white fw-bold'>{leave.finalstatus}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center py-5">
                <div className="empty-state">
                  <i className="fas fa-inbox mb-3" style={{ fontSize: '3rem', color: '#ccc' }}></i>
                  <h5 className="text-muted">No Leaves Found</h5>
                  <p className="text-muted">No assigned leaves are available at the moment.</p>
                </div>
              </div>
            )}
          </div>

          {/* Pagination */}
          <div className="d-flex justify-content-between align-items-center mt-4 pt-3 border-top">
            <div className="text-muted">
              Page {page}
            </div>
            <div className="d-flex gap-2">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
                className='btn btn-outline-primary'
                style={{
                  borderRadius: '8px',
                  transition: 'all 0.2s ease'
                }}
              >
                <i className="fas fa-chevron-left me-2"></i>
                Previous
              </button>
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={!hasMore}
                className='btn btn-primary'
                style={{
                  borderRadius: '8px',
                  transition: 'all 0.2s ease'
                }}
              >
                Next
                <i className="fas fa-chevron-right ms-2"></i>
              </button>
            </div>
          </div>
        </div>

        <Dialog 
          open={showDialog} 
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              borderRadius: '12px',
              boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
              overflow: 'hidden'
            }
          }}
        >
          <DialogTitle 
            sx={{
              backgroundColor: '#5B8FFF',
              color: 'white',
              padding: '16px 24px',
              '& .MuiIconButton-root': {
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)'
                }
              }
            }}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <div className='col text-center'>
                <span className='fw-bold' style={{ fontSize: '20px' }}>
                  {selectedLeave?.LeaveType} - {selectedLeave?.Duration === 0.5 ? "First Half" : selectedLeave?.Duration === 0.6 ? "Second Half" : selectedLeave?.Duration === 1.0 ? "Full Day" : `${selectedLeave?.Duration} ${selectedLeave?.subType}`} {selectedLeave?.LeaveSubType}
                </span>
              </div>
              <IconButton 
                onClick={handleCloseDialog} 
                size="small" 
                sx={{ 
                  marginRight: '-12px'
                }}
              >
                <i className="fa fa-times"></i>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent 
            sx={{
              backgroundColor: '#f5f5f5',
              padding: '24px',
              '& .card-body': {
                backgroundColor: 'white',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                padding: '20px'
              }
            }}
          >
            <div className='card-body'>
              <div className='row'>
                <div>
                  <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                    Status :
                    <span style={{ fontSize: '16px', fontWeight: '700' }} className={`status-text ${selectedLeave?.finalstatus === 'Approved' ? 'text-success' : selectedLeave?.finalstatus === 'Rejected' ? 'text-danger' : 'text-warning'}`}>
                      {selectedLeave?.finalstatus}
                    </span>
                  </span>
                  <div className="mt-2">
                    <span style={{ color: '#021317', fontWeight: '700', fontSize: '14px' }}>
                      Available Leave Balance:
                    </span>
                    <span style={{ color: '#000', fontWeight: '700', fontSize: '16px', marginLeft: '5px' }}>
                      {selectedLeave?.remaining_balance || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='mt-2'>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-suitcase' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>Leave is of <strong>{calculateDays()}</strong> {calculateDays() <= 1 ? "day" : "days"}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-calendar' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>From: {selectedLeave?.StartDate}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-calendar' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>To: {selectedLeave?.EndDate}</h6>
                  </div>
                  <div style={{ color: '#021317', fontWeight: '700' }}>
                    <i className='fa fa-file' />
                    <h6 className="d-inline ml-2" style={{ fontSize: '14px' }}>Attached Document: {selectedLeave?.AttachedDocumnet.FilePathName ? (
                      <a
                        href={selectedLeave.AttachedDocumnet.FilePathName}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(selectedLeave.AttachedDocumnet.FilePathName, '_blank');
                        }}
                        role="button"
                        target='_blank'
                        aria-label={`Download ${selectedLeave.AttachedDocumnet.originalFilename}`}
                        style={{ color: '#6366F1', fontWeight: 'bold' }}
                      >
                        {selectedLeave.AttachedDocumnet.originalFilename}
                      </a>
                    ) : (
                      <span>Not provided</span>
                    )}
                    </h6>
                  </div>
                </div>
              </div>

              <div>
                <h5 className="mt-4 ml-2 " style={{ color: '#021317', fontWeight: "700" }}>Leave History</h5>
              </div>
              <div class=" card chat-history mt-2" style={{ backgroundColor: '#F9FCFF' }}>
                <div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="d-flex flex-column mt-3">
                        <div className="d-flex align-items-center ml-3" style={{ fontSize: "12px", color: "#6366F1" }}>
                          <strong>{selectedLeave?.user_history[0].UpdatedUserName}</strong>
                          <div className="message-data-time ml-2">
                            {selectedLeave?.user_history[0].DateChanged}
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div>
                            <img
                              src={selectedLeave?.user_history[0].UpdatedUserimage || `/images/icon/Noprofile.webp`}
                              className="rounded-circle mr-2"
                              style={{ width: "50px", height: "50px", objectFit: "cover" }}
                              alt="avatar"
                            />
                          </div>
                          <div
                            className="card message other-message p-2 mr-5"
                            style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                            dangerouslySetInnerHTML={{ __html: selectedLeave?.user_history[0].Message }}
                          />
                        </div>
                      </div>
                    </li>
                    {selectedLeave?.user_history.map((historyItem, index) => (
                      index >= 1 && (
                        <li className="clearfix mt-2 d-flex justify-content-end ml-auto mr-1" key={index}>
                          <div className="d-flex flex-column align-items-end mt-3">
                            <div className="d-flex align-items-center mr-3" style={{ fontSize: "12px", color: "#6366F1" }}>
                              <div className="message-data-time mr-2">
                                {historyItem.DateChanged}
                              </div>
                              <strong>{historyItem.UpdatedUserName}</strong>
                            </div>
                            <div className="d-flex mt-1 mr-3">
                              <div
                                className="card message other-message p-2"
                                style={{ backgroundColor: "#A4A6A8", color: "#000000" }}
                                dangerouslySetInnerHTML={{ __html: historyItem.Message }}
                              />
                              <div>
                                <img
                                  src={historyItem.UpdatedUserimage || `/images/icon/Noprofile.webp`}
                                  className="rounded-circle ml-2"
                                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                  alt="avatar"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                marginRight: "8px",
                                fontSize: "12px",
                                fontWeight: "600",
                                color: historyItem.Status === "Rejected" ? "red" : "green"
                              }}
                            >
                              {historyItem.Status}
                            </div>
                          </div>
                        </li>
                      )
                    ))}
                  </ul>
                </div>
              </div>
              {selectedLeave?.finalstatus == "Pending" && (
                <div>
                  <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-success me-2" onClick={() => handleLeaveAction('Approved', selectedLeave)}>Approve</button>
                    <button type="button" className="btn btn-danger" onClick={() => handleLeaveAction('Rejected', selectedLeave)}>Reject</button>
                  </div>

                  {selectedAction && (
                    <div className="card mt-2 p-2">
                      <textarea
                        className="form-control mt-2"
                        value={dialogInput}
                        placeholder={selectedAction === "Approved" ? "Reason for Approval" : "Reason for Rejection"}
                        onChange={(e) => setDialogInput(e.target.value)}
                        style={{ height: '100px' }}
                      />
                      <div className="d-flex justify-content-center mt-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleDialogSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
export default AssignedLeaves;
