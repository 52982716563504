import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";
import ReactApexChart from 'react-apexcharts';
import { Button } from "@mui/material";
import { jsPDF } from "jspdf";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-datepicker/dist/react-datepicker.css";
import "./UserAnalysis.css";

import api from '../../../Authenticaltion/api';

const UserAnalysis = () => {
  const apiKey = "NWVkN2U1NTMtOGYxOC00NmM1LWIzOTgtOGYzOGI0NjM1MTZj";
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectPage, setProjectPage] = useState(1);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [projectSearchQuery, setProjectSearchQuery] = useState('');

  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [dateRange, setDateRange] = useState([sevenDaysAgo, today]);
  const [workSummary, setWorkSummary] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch Workspaces
  const fetchWorkspaces = async () => {
    try {
      const response = await api.get('/getAllWorkspaces');
      setWorkspaces(response.data);
      if (response.data && response.data.length > 0) {
        const firstWorkspace = response.data[0];
        setSelectedWorkspace(firstWorkspace);
      }
    } catch (error) {
      console.error('Error fetching workspaces:', error);
      setError('Failed to fetch workspaces. Please try again.');
    }
  };

  // Fetch Projects for the selected workspace
  const fetchProjects = async (page = 1, append = false) => {
    if (!selectedWorkspace || isLoadingProjects || (!hasMoreProjects && append)) return;

    setIsLoadingProjects(true);
    try {
      const payload = {
        name: projectSearchQuery || "",
        status: "ACTIVE",
        page: page,
        pageSize: 50,
        clients: null
      };

      const response = await axios.post(
        `https://global.api.clockify.me/workspaces/${selectedWorkspace.ClockifyWorkSpaceId}/projects/report-filters`,
        payload,
        { headers: { 'X-Api-Key': apiKey } }
      );

      // Transform the projects data
      const transformedProjects = response.data.map(project => ({
        id: project.id,
        name: project.name,
        color: project.color
      }));

      if (append) {
        setProjects(prev => [...prev, ...transformedProjects]);
      } else {
        setProjects(transformedProjects);
      }

      setHasMoreProjects(transformedProjects.length === 50);
      setProjectPage(page);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Failed to fetch projects. Please try again.');
    } finally {
      setIsLoadingProjects(false);
    }
  };

  // Handle workspace selection
  const handleWorkspaceChange = (e) => {
    const workspace = e.value;
    setSelectedWorkspace(workspace);
    setProjects([]); // Reset projects when workspace changes
    setSelectedProject(null);
    setProjectPage(1);
    setHasMoreProjects(true);
  };

  // Handle project search
  const handleProjectSearch = (event) => {
    setProjectSearchQuery(event.query);
    setProjectPage(1);
    setHasMoreProjects(true);
    fetchProjects(1, false);
  };

  // Fetch Work Summary
  const fetchWorkSummary = async () => {
    if (!selectedWorkspace) return;

    try {
      setLoading(true);
      setError(null);

      const [startDate, endDate] = dateRange;

      const payload = {
        dateRangeStart: startDate.toISOString(),
        dateRangeEnd: endDate.toISOString(),
        amountShown: "EARNED",
        amounts: ["EARNED"],
        customFields: null,
        description: "",
        kioskIds: [],
        rounding: false,
        sortOrder: "DESCENDING",
        summaryFilter: {
          sortColumn: "GROUP",
          groups: ["PROJECT", "TIMEENTRY"],
          summaryChartType: "PROJECT",
        },
        userCustomFields: null,
        userLocale: "en-US",
        withoutDescription: false,
        zoomLevel: "WEEK",
      };

      const response = await axios.post(
        `https://reports.api.clockify.me/workspaces/${selectedWorkspace.ClockifyWorkSpaceId}/async/reports/summary`,
        payload,
        {
          headers: { "X-Api-Key": apiKey, "Content-Type": "application/json" },
        }
      );

      const reportTaskId = response.data.reportTaskId;
      if (reportTaskId) {
        await fetchReportData(reportTaskId);
      }
    } catch (err) {
      console.error("Error fetching summary:", err);
      setError("Failed to fetch work summary. Please check the request data.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch Report Data
  const fetchReportData = async (reportTaskId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://reports.api.clockify.me/workspaces/${selectedWorkspace.ClockifyWorkSpaceId}/async/reports/summary/${reportTaskId}`,
        {
          headers: { "X-Api-Key": apiKey },
        }
      );

      const data = response.data.chart;
      const workData = [];
      const pieChartData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      };

      const lineChartData = {
        labels: [],
        datasets: [
          {
            label: "Total Hours Per Project",
            data: [],
            borderColor: [],
            backgroundColor: [],
            fill: true,
          },
        ],
      };

      // Predefined color palette for projects
      const colorPalette = [
        "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", 
        "#FF9F40", "#4CAF50", "#FF7043", "#42A5F5", "#FFEB3B"
      ];

      for (const date in data) {
        let colorIndex = 0;
        data[date].forEach((item) => {
          workData.push({
            projectName: item.projectName,
            totalTime: item.totalTime,
            totalBillableTime: item.totalBillableTime,
            entriesCount: item.entriesCount,
          });

          // Add labels, data, and colors dynamically
          pieChartData.labels.push(item.projectName);
          pieChartData.datasets[0].data.push(item.totalTime / 3600);
          const color = colorPalette[colorIndex % colorPalette.length];
          pieChartData.datasets[0].backgroundColor.push(color);
          pieChartData.datasets[0].hoverBackgroundColor.push(color);

          lineChartData.labels.push(item.projectName);
          lineChartData.datasets[0].data.push(item.totalTime / 3600);
          lineChartData.datasets[0].borderColor.push(color);
          lineChartData.datasets[0].backgroundColor.push(color + "33");

          colorIndex++;
        });
      }

      setWorkSummary(workData);
      setChartData(pieChartData);
      setLineChartData(lineChartData);
    } catch (err) {
      console.error("Error fetching detailed report data:", err);
      setError("Failed to fetch detailed report data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Lifecycle Effects
  useEffect(() => {
    fetchWorkspaces();
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      fetchProjects(1, false);
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace) {
      fetchWorkSummary();
    }
  }, [selectedWorkspace, dateRange]);

  // Render methods and other existing code remain the same...
  
  return (
    <div className="user-analysis-container">
      {/* Workspace Dropdown */}
   

      {loading && (
        <div className="loading-overlay">
          <div className="loader">
            <div className="loader-inner">
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
              <div className="loader-line-wrap">
                <div className="loader-line"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        {/* Header Title */}
        <h1 style={{ margin: '0 0 1.5rem 0', fontSize: '1.2rem', fontWeight: '600',color: 'black',textAlign: 'center' }}>
          Time Analysis Dashboard
        </h1>

        {/* Filters Section */}
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '1rem' }}>
        <div className="workspace-selector mb-3">
        <Dropdown 
          value={selectedWorkspace ? selectedWorkspace.workspace_name : null} 
          options={workspaces} 
          onChange={handleWorkspaceChange}
          optionLabel="workspace_name" 
          placeholder={selectedWorkspace ? selectedWorkspace.workspace_name : null}  
          className="w-full md:w-14rem" 
        />
      </div>

          <div >
            <Calendar
              value={dateRange}
              onChange={(e) => setDateRange(e.value)}
              selectionMode="range"
              readOnlyInput
              showIcon
              className="p-calendar"
              style={{ width: '100%', borderRadius: '6px', padding: '0.5rem' }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              flex: '0 0 auto',
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: '#dc3545',
                color: '#fff',
                fontWeight: '500',
                padding: '0.7rem 1.5rem',
                borderRadius: '6px',
              }}
              onClick={() => {
                const doc = new jsPDF();
                doc.text("User Work Summary Report", 10, 10);
                workSummary.forEach((item, index) => {
                  doc.text(
                    `${index + 1}. ${item.projectName} - ${formatTime(item.totalTime)}`,
                    10,
                    20 + index * 10
                  );
                });
                doc.save("user_work_summary.pdf");
              }}
              startIcon={<i className="pi pi-file-pdf" />}
            >
              Export PDF
            </Button>
          </div>
        </div>

        {/* Summary Cards */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '2rem',
            gap: '1.5rem',
          }}
        >
          <div
            style={{
              background: '#ffffff',
              color: '#03045e',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              flex: '1',
              textAlign: 'center',
            }}
          >
            <div style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem' }}>
              Total Hours
            </div>
            <div style={{ fontSize: '1.8rem', fontWeight: '700' }}>
              {(workSummary.reduce((acc, curr) => acc + curr.totalTime, 0) / 3600).toFixed(1)} hrs
            </div>
          </div>

          <div
            style={{
              background: '#ffffff',
              color: '#03045e',
              padding: '1rem 1.5rem',
              borderRadius: '8px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              flex: '1',
              textAlign: 'center',
            }}
          >
            <div style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem' }}>
              Average Daily Hours
            </div>
            <div style={{ fontSize: '1.8rem', fontWeight: '700' }}>
              {(workSummary.reduce((acc, curr) => acc + curr.totalTime, 0) / 3600 / 7).toFixed(1)} hrs
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="charts-container">
        <div className="chart-card">
          <h3 style={{ margin: '0 0 1.5rem 0', color: '#495057' }}>Daily Time Distribution</h3>
          {chartData && <Chart type="bar" data={chartData} />}
        </div>
        <div className="chart-card">
          <h3 style={{ margin: '0 0 1.5rem 0', color: '#495057' }}>Weekly Trend</h3>
          {lineChartData && <Chart type="line" data={lineChartData} />}
        </div>
      </div>

      <div className="table-chart-container">
        <div className="table-section">
          <h3 style={{ margin: '0 0 1.5rem 0', color: '#495057' }}>Time Entries</h3>
          <DataTable
            value={workSummary}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10]}
            className="p-datatable-striped"
            scrollable
            scrollHeight="400px"
          >
            <Column
              field="projectName"
              header="Project"
              sortable
              style={{ width: '40%' }}
            />
            <Column
              field="totalTime"
              header="Hours"
              body={(rowData) => (rowData.totalTime / 3600).toFixed(2)}
              sortable
              style={{ width: '30%' }}
            />
            <Column 
              field="totalBillableTime" 
              header="Billable" 
              body={(rowData) => (rowData.totalBillableTime / 3600).toFixed(2)}
              sortable
              style={{ width: '30%' }}
            />
          </DataTable>
        </div>
        <div className="pie-chart-section">
          <h3 style={{ margin: '0 0 1.5rem 0', color: '#495057', fontSize: '1.5rem' }}>Time Distribution</h3>
          <div className="pie-chart-container" style={{ minHeight: '500px', width: '100%' }}>
          {chartData && chartData.datasets && chartData.datasets[0] && (
  <ReactApexChart
    type="donut"
    height={500}
    width="100%"
    series={chartData.datasets[0].data.map((val) => parseFloat(val))}
    options={{
      chart: {
        type: 'donut',
        height: 500,
        background: 'transparent'
      },
      labels: chartData.labels,
      colors: chartData.datasets[0].backgroundColor,
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '14px',
        markers: {
          width: 12,
          height: 12,
          radius: 6
        },
        itemMargin: {
          horizontal: 10,
          vertical: 5
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '16px',
                fontWeight: 600
              },
              value: {
                show: true,
                fontSize: '16px',
                fontWeight: 500,
                formatter: function (val) {
                  const value = parseFloat(val);
                  return !isNaN(value) ? value.toFixed(2) + ' hrs' : '0 hrs';
                }
              },
              total: {
                show: true,
                fontSize: '16px',
                fontWeight: 600,
                label: 'Total Hours',
                formatter: function (w) {
                  const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return total.toFixed(2) + ' hrs';
                }
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(1) + '%';
        },
        style: {
          fontSize: '14px',
          fontWeight: 500
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            const value = parseFloat(val);
            return !isNaN(value) ? value.toFixed(2) + ' hrs' : '0 hrs';
          }
        },
        style: {
          fontSize: '14px'
        }
      },
      stroke: {
        width: 2
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 400
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }}
  />
)}

          </div>
        </div>
      </div>
    </div>
  );
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours} hours ${minutes} minutes`;
};

export default UserAnalysis;
