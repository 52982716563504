import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useAuth from './Authenticaltion/roleFinder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRegistered } from '@fortawesome/free-regular-svg-icons';
import './MobileSidebar.css';
import { useNavigate } from 'react-router-dom';
import clockifyIcon from './clockify2.svg';
import Lottie from 'lottie-react';
import capture from './Animation - 1735561736666.json';
 
const Mobilesidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [activeRoute, setActiveRoute] = useState(location.pathname);
    const { role } = useAuth();
    const [activeSection, setActiveSection] = useState(role === 'MASTERADMIN' ? 'admin' : 'basic');
    const [openDropdown, setOpenDropdown] = useState('');

    const handleRouteClick = (path) => {
        setActiveRoute(path);
        navigate(path);
    };

    const handleSectionClick = (section, defaultPath) => {
        if (activeSection !== section) {
            setActiveSection(section);
            handleRouteClick(defaultPath);
        }
    };

    const handleDropdownClick = (name, e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenDropdown(openDropdown === name ? '' : name);
    };

    useEffect(() => {
        setActiveRoute(location.pathname);
    }, [location.pathname]);

    const adminRoutes = [
        { path: "/Dashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/EmployeeList', name: "Employee Details", logo: "fas fa-user-plus" },
        { path: '/TimeTracker', name: "Time Tracker", logo: 'fas fa-stopwatch' },
        {
            name: "Clockify-time-report",
            logo: <img src={clockifyIcon} alt="Clockify Icon" style={{ width: '15px', height: '20px',marginRight:'5px' }} />,
            subItems: [
                { path: '/Clockify_Report', name: "Report", logo: <i className='fa fa-clock' title='View Clockify Report' style={{ color: 'black', width: '15px',marginRight:'5px' }}></i>, tooltip: 'View Clockify Report' },
                { path: '/UserAnalysis-Report', name: "Project Analysis", logo: <i className='fa fa-pie-chart' title='View User Analysis' style={{ color: 'black', width: '15px',marginRight:'5px' }}></i>, tooltip: 'View User Analysis' }
            ]
        },
        { path: '/UploadFile', name: "Upload File", logo: 'fas fa-upload' },
        { path: '/Holidays', name: "Holidays", logo: 'fas fa-calendar-alt' },
        { path: '/Manage-Holidays', name: "Manage Holidays", logo: 'fas fa-cogs' },
        { path: '/ViewAllLeave', name: 'User Leaves', logo: "fas fa-check-circle" },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' },
        

    ];

    const superuserRoutes = [
        { path: "/Dashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/EmployeeList', name: "Employee Details", logo: "fas fa-user-plus" },
        { path: '/TimeTracker', name: "Time Tracker", logo: 'fas fa-stopwatch' },
        {
            name: "Clockify-time-report",
            logo: <img src={clockifyIcon} alt="Clockify Icon" style={{ width: '15px', height: '20px',marginRight:'5px' }} />,
            subItems: [
                { path: '/Clockify_Report', name: "Report", logo: <i className='fa fa-clock' title='View Clockify Report' style={{ color: 'black', width: '15px',marginRight:'5px' }}></i>, tooltip: 'View Clockify Report' },
                { path: '/UserAnalysis-Report', name: "Project Analysis", logo: <i className='fa fa-pie-chart' title='View User Analysis' style={{ color: 'black', width: '15px',marginRight:'5px' }}></i>, tooltip: 'View User Analysis' }
            ]
        },
        { path: '/UploadFile', name: "Upload File", logo: 'fas fa-upload' },
        { path: '/Holidays', name: "Holidays", logo: 'fas fa-calendar-alt' },
        { path: '/Manage-Holidays', name: "Manage Holidays", logo: 'fas fa-cogs' },
        { path: '/ViewAllLeave', name: 'User Leaves', logo: "fas fa-check-circle" },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' },
        
    ];

    const masterAdminRoutes = [
        { path: "/Dashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/EmployeeList', name: "Employee Details", logo: "fas fa-user-plus" },
        { path: '/TimeTracker', name: "Time Tracker", logo: 'fas fa-stopwatch' },
        {
            name: "Clockify-time-report",
            logo: <img src={clockifyIcon} alt="Clockify Icon" style={{ width: '15px', height: '20px',marginRight:'5px' }} />,
            subItems: [
                { path: '/Clockify_Report', name: "Report", logo: <i className='fa fa-clock' title='View Clockify Report' style={{ color: 'black', width: '15px',marginRight:'5px' }}></i>, tooltip: 'View Clockify Report' },
                { path: '/UserAnalysis-Report', name: "Project Analysis", logo: <i className='fa-pie-chart' title='View User Analysis' style={{ color: 'black', width: '15px',marginRight:'5px' }}></i>, tooltip: 'View User Analysis' }
            ]
        },
        { path: '/Holidays', name: "Holidays", logo: 'far fa-calendar-alt' },
        { path: '/Manage-Holidays', name: "Manage Holidays", logo: 'fas fa-cogs' },
        { path: '/UploadFile', name: 'Upload File', logo: 'fas fa-upload' },
        { path: '/ViewAllLeave', name: 'User leaves', logo: 'fas fa-check' },
        { path: '/AppliedReimbursement', name: 'Reimbursement', logo: 'fa fa-rupee' },
        { path: '/Admin-Salaryslip', name: 'Salary Slip', logo: 'fa fa-credit-card' },
        
    ];

    const addRoutes = [
        { path: '/User-Leaves', name: 'Verify Leaves', logo: "fas fa-check-circle" },
    ];

    const finalRoutes = { Advance: [], Basic: [] };

    const basicRoutes = [
        { path: "/UserDashboard", name: "Dashboard", logo: 'fas fa-home' },
        { path: '/Leaves', name: "My Leaves", logo: 'fas fa-bed' },
        { path: '/user-TimeTracker', name: 'Time Tracker', logo: 'fas fa-stopwatch' },
        { path: '/Reimbursement', name: 'Reimbursement', logo: 'fas fa-rupee', },
        { path: '/Holidays', name: "Holidays", logo: 'far fa-calendar-alt' },
        { path: '/SalarySlips', name: "Salary Slips", logo: 'fas fa-file-alt' },
        { path: '/Resignation', name: 'Resignation', logo: <FontAwesomeIcon icon={faRegistered} style={{color: "black",paddingRight:'5px'}} /> }

    ];

    const getRoutesByRole = (role) => {
        switch (role) {
            case 'ADMIN':
                finalRoutes.Advance = adminRoutes;
                finalRoutes.Basic = basicRoutes;
                finalRoutes.Basic.push(...addRoutes);
                return finalRoutes;
            case 'SUPERADMIN':
                finalRoutes.Advance = superuserRoutes;
                finalRoutes.Basic = basicRoutes;
                finalRoutes.Basic.push(...addRoutes);
                return finalRoutes;
            case 'MASTERADMIN':
                finalRoutes.Advance = masterAdminRoutes;
                return finalRoutes;
            case 'USER':
                finalRoutes.Basic = basicRoutes;
                return finalRoutes;
            case 'MANAGER':
                finalRoutes.Basic = basicRoutes;
                finalRoutes.Basic.push(...addRoutes);
                return finalRoutes;
            default:
                return [];
        }
    };

    const userRoutesToShow = getRoutesByRole(role);

    const renderSection = (sectionName, routes, defaultPath) => (
        <>
            <li
                className="w-100 d-flex justify-content-center section-header"
                onClick={() => handleSectionClick(sectionName, defaultPath)}
                style={{ cursor: 'pointer', borderRadius: 'none', }}
            >
                {sectionName === 'admin' ? 'Admin Features' : 'My Features'}
            </li>
            <hr style={{ color: '#003AFF' }} />
            <div 
                className={`section ${activeSection === sectionName ? 'open' : ''}`}
                data-section={sectionName === 'admin' ? 'admin' : ''}
            >
                {routes.map((route) => (
                    route.subItems ? (
                        <li
                            className={`menu-item has-dropdown ${openDropdown === route.name ? 'open' : ''}`}
                            key={route.name}
                            style={{ cursor: 'pointer', color: "#DCDCDC" }}
                        >
                            <NavLink
                                to="#"
                                onClick={(e) => handleDropdownClick(route.name, e)}
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    fontSize: '13px',
                                    fontWeight: 'normal'
                                }}
                            >
                                {typeof route.logo === 'string' ? (
                                    <i className={route.logo} style={{ width: '2px', color: "#030303" }} />
                                ) : (
                                    route.logo
                                )}
                                <span style={{ color: " #000000", fontWeight: "600", fontSize: "12px" }}> {route.name}</span>
                            </NavLink>
                            {openDropdown === route.name && (
                                <ul>
                                    {route.subItems.map((subItem) => (
                                        <li
                                            className={`menu-item ${activeRoute === subItem.path ? 'active sidebar_active' : 'sidebar_deactive'}`}
                                            key={subItem.name}
                                            style={{ cursor: 'pointer', color: "black" }}
                                            onClick={() => handleRouteClick(subItem.path)}
                                        >
                                            <NavLink
                                                to={subItem.path}
                                                style={{
                                                    color: 'black',
                                                    textDecoration: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {subItem.logo}
                                                <span style={{ marginLeft: '5px', color: 'black' }}>{subItem.name}</span>
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ) : (
                        <li
                            className={`menu-item ${activeRoute === route.path ? 'active sidebar_active' : 'sidebar_deactive'}`}
                            key={route.name}
                            style={{ cursor: 'pointer', color: "#DCDCDC" }}
                            onClick={() => handleRouteClick(route.path)}
                        >
                            <NavLink
                                to={route.path}
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {typeof route.logo === 'string' ? (
                                    <i className={route.logo} style={{ marginRight: '10px', color: "#030303" }} />
                                ) : (
                                    route.logo
                                )}
                                <span style={{ color: " #000000", fontWeight: "600", fontSize: "12px" }}>{route.name}</span>
                            </NavLink>
                        </li>
                    )
                ))}
            </div>
        </>
    );

    return (
        <aside className="menu-sidebar d-none d-lg-block">
            <div className="logo" style={{ position: 'relative', display: 'inline-block' }}>
                <img 
                    src="/images/LogoSpc.png" 
                    alt="WFMS"
                    style={{}}
                />
                <div 
                    style={{
                        position: 'absolute',
                        top: '-3%',
                        right: '24%',
                        width: '37%',

                        zIndex: 10,
                    }}
                >
                   
                </div>
            </div>
            <div className="menu-sidebar__content js-scrollbar1">
                <nav className="navbar-sidebar">
                    <ul className="list-unstyled navbar__list">
                        {finalRoutes.Advance.length > 0 && renderSection('admin', finalRoutes.Advance, '/Dashboard')}
                        {finalRoutes.Basic.length > 0 && renderSection('basic', finalRoutes.Basic, '/UserDashboard')}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Mobilesidebar;