import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import api from '../../Authenticaltion/api';
import { setAuthToken } from '../../Authenticaltion/api';
import Swal from 'sweetalert2';
import './Login.css';
import loginIcon from './login.svg';
import useAuth from '../../Authenticaltion/roleFinder';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const RoleFinder = useAuth();
  const [pwd, setPwd] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/login', {
        email,
        pwd,
      });
      console.log("response.status is", response.status);
      if (response.status === 200) {
        const { token, role } = response.data;
        localStorage.setItem('token', token);
        setAuthToken(token);

        if (role === 'SUPERUSER') {
          navigate('/Dashboard');
        } else {
          navigate('/UserDashboard');
        }
      } else if (response.status === 500) {
        const errorMessage = response.data.error;
        Swal.fire({
          title: 'Server Error',
          text: errorMessage,
          icon: 'error',
          timer: 2000,
        });
      }
    } catch (error) {
      console.log('API Request Error:', error.response.data.message);
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        timer: 2000,
      });
    }
  };

  useEffect(() => {
    console.log("Role is", RoleFinder.role);
    if (RoleFinder.role === 'ADMIN') {
      navigate('/Dashboard');
    } else if (RoleFinder.role === 'SUPERADMIN') {
      navigate('/Dashboard');

    } 
    else if (RoleFinder.role === 'USER' || RoleFinder.role === 'MANAGER') {
      navigate('/UserDashboard');
    }
    else if(RoleFinder.role === 'MASTERADMIN') {
      navigate('/Dashboard');
    }
  }, [RoleFinder.role, navigate]);

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-left">
          <div className="login-image-container">
            <img src={loginIcon} alt="Login" className="login-illustration" />
          </div>
        </div>
        <div className="login-right">
          <div className="login-form-container">
            <div className="login-header">
              <div className="logo-container">
                <a href="https://wfms.ajatus.in/">
                  <img src="images/LogoSpc.png" alt="WFMS Logo" />
                </a>
              </div>
              <h2>Welcome Back 👋</h2>
              <p>Please sign in to your account to get started</p>
            </div>
            
            <form onSubmit={handleSubmit} className="login-form">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <div className="input-container">
                  <i className="fas fa-envelope input-icon"></i>
                  <input
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    required
                  />
                </div>
              </div>
              
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="input-container">
                  <i className="fas fa-lock input-icon"></i>
                  <input
                    className="form-control"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    required
                  />
                  <i
                    className={`password-toggle ${showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}`}
                    onClick={() => setShowPassword(!showPassword)}
                  ></i>
                </div>
              </div>
              
              <div className="form-actions">
                <button type="submit" className="btn-login">
                  Sign In
                </button>
              </div>
              <div className="signup-section">
                <p>Don't have an account?</p>
                <Link to="/Register" className="btn-signup">
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
