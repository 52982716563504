import React, { useState, useRef, useEffect } from 'react';
import './ChatBoard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';

const predefinedQA = {
    '1': {
        question: "What is the Workforce Management System?",
        answer: "Our Workforce Management System is a comprehensive solution that helps organizations efficiently manage their employees, schedules, attendance, and performance tracking all in one place."
    },
    '2': {
        question: "How does the attendance tracking work?",
        answer: "The attendance tracking system uses biometric authentication and mobile check-ins to record employee attendance. It provides real-time tracking, generates automated reports, and flags any irregularities."
    },
    '3': {
        question: "What are the key features of scheduling?",
        answer: "Our scheduling features include automated shift planning, employee availability management, leave management, shift swapping, and real-time schedule updates with mobile notifications."
    },
    '4': {
        question: "How does performance tracking work?",
        answer: "Performance tracking includes KPI monitoring, goal setting, automated performance reviews, skill assessments, and detailed analytics dashboards for both employees and managers."
    },
    '5': {
        question: "What reports are available in the system?",
        answer: "The system offers various reports including attendance summaries, overtime analysis, leave balances, performance metrics, productivity reports, and custom report generation capabilities."
    }
};

const formatWelcomeMessage = () => {
    const welcomeText = "Hello! I'm your Workforce Management Assistant. Choose a number to learn more:";
    const questions = Object.entries(predefinedQA).map(([num, qa]) => (
        `<div class="question-list">
            <span class="question-number">${num}.</span>
            <span class="question-text">${qa.question}</span>
        </div>`
    )).join('');
    
    return `<div class="welcome-text">${welcomeText}</div>${questions}`;
};

const ChatBoard = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (isOpen && messages.length === 0) {
            const welcomeMessage = {
                id: Date.now(),
                html: formatWelcomeMessage(),
                sender: 'bot',
                timestamp: new Date().toLocaleTimeString()
            };
            setMessages([welcomeMessage]);
        }
    }, [isOpen]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (newMessage.trim() === '') return;

        const userMessage = {
            id: Date.now(),
            text: newMessage,
            sender: 'user',
            timestamp: new Date().toLocaleTimeString()
        };

        const botResponse = {
            id: Date.now() + 1,
            html: predefinedQA[newMessage]
                ? `<div class="answer-text">${predefinedQA[newMessage].answer}</div>`
                : `<div class="answer-text">Please choose a number between 1-5 to get information about our Workforce Management System.</div>`,
            sender: 'bot',
            timestamp: new Date().toLocaleTimeString()
        };

        setMessages([...messages, userMessage, botResponse]);
        setNewMessage('');
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="chat-widget">
            {!isOpen && (
                <button className="chat-icon" onClick={toggleChat}>
                    <FontAwesomeIcon icon={faRobot} className="bot-icon" />
                    <span className="chat-tooltip">Ask about WMS</span>
                </button>
            )}
            
            {isOpen && (
                <div className="chat-board">
                    <div className="chat-header">
                        <div className="header-content">
                            <FontAwesomeIcon icon={faRobot} className="header-icon" />
                            <h3>WMS Assistant</h3>
                        </div>
                        <button className="close-button" onClick={toggleChat}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div className="chat-messages">
                        {messages.map((message) => (
                            <div 
                                key={message.id} 
                                className={`message ${message.sender === 'user' ? 'user-message' : 'bot-message'}`}
                            >
                                <div className="message-content">
                                    {message.text ? (
                                        <p>{message.text}</p>
                                    ) : (
                                        <div dangerouslySetInnerHTML={{ __html: message.html }} />
                                    )}
                                    <span className="timestamp">{message.timestamp}</span>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <form className="chat-input" onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Enter a number (1-5)..."
                        />
                        <button type="submit">
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default ChatBoard;
