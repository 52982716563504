import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useAuth from './Authenticaltion/roleFinder';
import { setAuthToken } from './Authenticaltion/api';
import api from './Authenticaltion/api';
import { ConfirmDialog } from 'primereact/confirmdialog';
import jwt_decode from 'jwt-decode';
import './DesktopHeader.css'
import Swal from 'sweetalert2';

const DesktopHeader = () => {
    const { role } = useAuth();
    const navigate = useNavigate();
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [dropdownState, setDropdownState] = useState({
        isAccoutModalOpen: false,
        isNotiOpen: false,
        isEmailOpen: false,
        isMessageOpen: false,
    });
    const [UserData, SetUserData] = useState(null)
    const handleDropdownToggle = (dropdownType) => {
        setDropdownState(prevState => ({
            ...prevState,
            [dropdownType]: !prevState[dropdownType],
            isAccoutModalOpen: dropdownType === 'isAccoutModalOpen' ? !prevState[dropdownType] : false,
            isNotiOpen: dropdownType === 'isNotiOpen' ? !prevState[dropdownType] : false,
            isEmailOpen: dropdownType === 'isEmailOpen' ? !prevState[dropdownType] : false,
            isMessageOpen: dropdownType === 'isMessageOpen' ? !prevState[dropdownType] : false,
        }));
    };
    const handleLogoutClick = () => {
        localStorage.clear()
        setAuthToken('');
        navigate('/'); // Clear the token in the Axios instance
    }
    const handleLogoutModal = () => {

        
        Swal.fire({
            title: 'Are you sure?',
            // text: "You have to login again once you logout!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'swal-button-width'
            }

        }).then((result) => {
            if (result.isConfirmed) {
                handleLogoutClick()
            }
        });

    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        console.log("Prining navbar token ", token)

        if (token) {
            // Token exists in local storage, decode it to get user data
            console.log("token available")
            try {
                const decodedToken = jwt_decode(token)// You need to implement a function to decode the token
                SetUserData(decodedToken);

            } catch (error) {
                console.error('Error decoding token', error);
            }
        }
    }, []);

    return (
        <>
            <header className="header-desktop">
                <div className="section__content section__content--p20">
                    <div className="container-fluid">
                        <div className="header-wrap mt-2">
                            <form className="form-header" action method="POST">
                                <h3 style={{ color: "#1C8FFC", weight: "900" }}>{UserData?.companyName}</h3>
                            </form>
                            <div className="header-button">
                                <div className="noti-wrap">
                                    <div className={`noti__item js-item-menu ${dropdownState.isMessageOpen ? 'show-dropdown' : ''}`}>
                                        <i className="zmdi zmdi-comment-more" onClick={() => handleDropdownToggle('isMessageOpen')} />
                                        <div className="mess-dropdown js-dropdown">
                                            <div className="mess__title " >
                                                <p>No Message Available</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`noti__item js-item-menu ${dropdownState.isNotiOpen ? 'show-dropdown' : ''}`}>
                                        <i className="zmdi zmdi-notifications" onClick={() => handleDropdownToggle('isNotiOpen')} />
                                        <div className="notifi-dropdown js-dropdown">
                                            <div className="notifi__title">
                                                <p>No notification available</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="account-wrap">
                                    <div className={`account-item clearfix js-item-menu ${dropdownState.isAccoutModalOpen ? 'show-dropdown' : ''}`}>
                                        <div className="image rounded-circle" onClick={() => handleDropdownToggle('isAccoutModalOpen')}>
                                            {UserData?.img_url && (
                                                <img src={UserData.img_url} alt="User" />
                                            )}
                                            {!UserData?.img_url && (
                                                <img src="images/icon/Noprofile.webp" alt="Static Image" />
                                            )}
                                        </div>
                                        <div className="content">
                                            <h4 className="js-acc-btn text-white" onClick={() => handleDropdownToggle('isAccoutModalOpen')} style={{ cursor: 'pointer' }}>
                                                {UserData?.Name}
                                            </h4>
                                        </div>
                                        <div className={`account-dropdown js-dropdown ${dropdownState.isAccoutModalOpen ? 'show' : ''}`}>
                                            <div className="info clearfix">
                                                <div className="image">
                                                    {UserData?.img_url && (
                                                        <img src={UserData.img_url} alt="User" />
                                                    )}
                                                    {!UserData?.img_url && (
                                                        <img src="images/icon/Noprofile.webp" alt="Static Image" />
                                                    )}
                                                </div>
                                                <div className="content">
                                                    <h5 className="name">
                                                        {UserData?.Name}
                                                    </h5>
                                                    <span className="email">{UserData?.email}</span>
                                                </div>
                                            </div>
                                            {
                                                role === 'MASTERADMIN' ? (
                                                    <>   <Link to="/company-rules" onClick={() => setDropdownState(prevState => ({ ...prevState, isAccoutModalOpen: false }))}><i className="fa fas fa-info-circle" style={{ marginRight: '5px', color: '#5d8ddf' }}></i><span className='nameChanger'>Rules</span></Link><br></br>
                                                        <hr className="divider" />
                                                        <Link to="/UserProfile" onClick={() => setDropdownState(prevState => ({ ...prevState, isAccoutModalOpen: false }))}> <i className="fa fas fa-building" style={{ marginRight: '5px', color: '#5d8ddf' }}></i><span className='nameChanger'>company profile</span></Link>
                                                    </>

                                                ) : role === 'SUPERADMIN' ?(
                                                    <>
                                                     <Link to="/company-rules" onClick={() => setDropdownState(prevState => ({ ...prevState, isAccoutModalOpen: false }))}><i className="fa fas fa-info-circle" style={{ marginRight: '5px', color: '#5d8ddf' }}></i><span className='nameChanger'>Rules</span></Link><br></br>
                                                    <hr className="divider" />
                                                    <Link to="/Profilepage" onClick={() => setDropdownState(prevState => ({ ...prevState, isAccoutModalOpen: false }))}> <i className="fa fa-thin fa-user" style={{ marginRight: '5px', color: '#5d8ddf' }}></i><span className='nameChanger'>Profile</span></Link>
                                                    </>
                                                )
                                                : (<>
                                                    <Link to="/Profilepage" onClick={() => setDropdownState(prevState => ({ ...prevState, isAccoutModalOpen: false }))}> <i className="fa fa-thin fa-user" style={{ marginRight: '5px', color: '#5d8ddf' }}></i><span className='nameChanger'>Profile</span></Link>
                                                </>
                                                )
                                            }
                                            <div className="account-dropdown__footer" onClick={handleLogoutModal} style={{ backgroundColorL: 'rgb(178, 0, 255,0.8)' }} >
                                                <div>
                                                    <i className="zmdi zmdi-power" style={{ backgroundColorL: 'rgb(178, 0, 255,0.8)', color: 'white', display: 'flex', padding: '9px' }}>
                                                        <span style={{ paddingLeft: '9px' }}>Logout </span>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ConfirmDialog />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default DesktopHeader