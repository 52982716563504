/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import './LandingPage.css';

import Header from './components/Header';

import Hero from './components/Hero';



const LandingPage = () => {





  return (

    <>

    <Header />

    <Hero />

    <div  >

    <div className="about-section" id="about">

      <div className="container">

        <div className="about-header">

          <span className="section-tag">WORKFORCE MANAGEMENT</span>

          <h1 className="about-title">Streamline Your<br />Employee Management</h1>

          <p className="about-description">

            Efficiently manage your workforce with our comprehensive employee tracking and management system. Monitor time entries, leave requests, and performance metrics all in one place.

          </p>

          <button className="explore-btn">

            Explore Features →

          </button>

        </div>



        <div className="features-grid">

          <div className="feature-card">

            <div className="feature-icon">

              <i className="bi bi-clock-history"></i>

            </div>

            <h3>Time Tracking</h3>

            <p>Accurate time entry and attendance monitoring for better workforce management and productivity analysis</p>

          </div>



          <div className="feature-card">

            <div className="feature-icon">

              <i className="bi bi-calendar-check"></i>

            </div>

            <h3>Leave Management</h3>

            <p>Streamlined leave request processing with automated approval workflows and balance tracking</p>

          </div>



          <div className="feature-card">

            <div className="feature-icon">

              <i className="bi bi-graph-up"></i>

            </div>

            <h3>Performance Metrics</h3>

            <p>Comprehensive performance tracking with customizable KPIs and real-time analytics</p>

          </div>



          <div className="feature-card">

            <div className="feature-icon">

              <i className="bi bi-people"></i>

            </div>

            <h3>Employee Directory</h3>

            <p>Centralized employee database with detailed profiles, roles, and organizational structure</p>

          </div>

        </div>

      </div>

    </div>



    <div className="row service_section bg-white" id="features" style={{ fontFamily: "system-ui",padding: '6rem 1rem' }}>

  <div className="row">

    <h2

      className="d-flex justify-content-center align-items-center section_tab_title pt-3"

      style={{

        fontSize: '28px',

        fontWeight: 'bold',

        color: '#333',

        marginBottom: '30px',

      }}

    >

      Features

    </h2>

  </div>

  <div className="row" style={{flexDirection: 'row-reverse'}}>

    <div

      className="col-md-6 d-flex justify-content-center align-items-center "

      style={{

        padding: '20px',

        textAlign: 'center',

      }}

    >

      <img

        src="./images/Features.jpg"

        className="img-fluid rounded"

        alt="Features"

        style={{ maxWidth: '70%', height: 'auto' }}

      />

    </div>

    <div

      className="col-md-6 d-flex justify-content-center align-items-center"

      style={{

        padding: '20px',

      }}

    >

      <div className="row">

        <div className="col-md-6 mb-4">

          <div className="feature-box" style={{ textAlign: 'center' }}>

            <i

              className="fas fa-check icon"

              style={{

                fontSize: '20px',

                color: '#007bff',

                marginBottom: '15px',

              }}

            ></i>

            <h4 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>Attendance Tracking</h4>

            <p

              className="feature-description"

              style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}

            >

              Track attendance efficiently and manage employee presence seamlessly.

            </p>

          </div>

        </div>

        <div className="col-md-6 mb-4">

          <div className="feature-box" style={{ textAlign: 'center' }}>

            <i

              className="fas fa-check icon"

              style={{

                fontSize: '2rem',

                color: '#007bff',

                marginBottom: '15px',

              }}

            ></i>

            <h4 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>Late Arrival Monitoring</h4>

            <p

              className="feature-description"

              style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}

            >

              Monitor and manage late arrivals to ensure punctuality.

            </p>

          </div>

        </div>

        <div className="col-md-6 mb-4">

          <div className="feature-box" style={{ textAlign: 'center' }}>

            <i

              className="fas fa-check icon"

              style={{

                fontSize: '2rem',

                color: '#007bff',

                marginBottom: '15px',

              }}

            ></i>

            <h4 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>Leave Management</h4>

            <p

              className="feature-description"

              style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}

            >

              Handle leave requests and track leave balances effortlessly.

            </p>

          </div>

        </div>

        <div className="col-md-6 mb-4">

          <div className="feature-box" style={{ textAlign: 'center' }}>

            <i

              className="fas fa-check icon"

              style={{

                fontSize: '2rem',

                color: '#007bff',

                marginBottom: '15px',

              }}

            ></i>

            <h4 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>Holiday Calendar</h4>

            <p

              className="feature-description"

              style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}

            >

              View and manage holidays and special dates easily.

            </p>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>



      <div class="pricing-container" id="Pricing" style={{ fontFamily: "system-ui",padding: '6rem 1rem' }}>

        <h1 class="title" style={{color: 'black', textAlign: 'center'}}>Subscription Plans</h1>

        <div class="pricing">

            <div class="pricing-box">

                <h2 class="plan-name">Free Plan</h2>

                <h3 class="price">$0 / mo</h3>

                

                <ul >

                    <li  >Aida dere</li>

                    <li>Nec feugiat nisl</li>

                    <li>Nulla at volutpat dola</li>

                    <li class="na">Pharetra massa</li>

                    <li class="na">Massa ultricies mi</li>

                </ul>

                <a href="#" class="btn-buy">Buy Now</a>

            </div>

            <div class="pricing-box featured">

                <h2 class="plan-name">Starter Plan</h2>

                <small class="small-colored">Best offer!</small>

                <h3 class="price">$19 / mo</h3>

                {/* <img src="assets/img/pricing-free.png" class="img-fluid" alt="Starter Plan" /> */}

                <ul>

                    <li>Aida dere</li>

                    <li>Nec feugiat nisl</li>

                    <li>Nulla at volutpat dola</li>

                    <li class="na">Pharetra massa</li>

                    <li class="na">Massa ultricies mi</li>

                </ul>

                <a href="#" class="btn-buy">Buy Now</a>

            </div>

            <div class="pricing-box">

                <h2 class="plan-name">Business Plan</h2>

                <h3 class="price">$29 / mo</h3>

                {/* <img src="assets/img/pricing-free.png" class="img-fluid" alt="Business Plan" /> */}

                <ul>

                    <li>Aida dere</li>

                    <li>Nec feugiat nisl</li>

                    <li>Nulla at volutpat dola</li>

                    <li >Pharetra massa</li>

                    <li class="na">Massa ultricies mi</li>

                </ul>

                <a href="#" class="btn-buy">Buy Now</a>

            </div>

            <div class="pricing-box">

                <h2 class="plan-name">Ultimate Plan</h2>

                <h3 class="price">$49 / mo</h3>

                {/* <img src="assets/img/pricing-free.png" class="img-fluid" alt="Ultimate Plan" /> */}

                <ul>

                    <li>Aida dere</li>

                    <li>Nec feugiat nisl</li>

                    <li>Nulla at volutpat dola</li>

                    <li class="na">Pharetra massa</li>

                    <li class="na">Massa ultricies mi</li>

                </ul>

                <a href="#" class="btn-buy">Buy Now</a>

            </div>

        </div>

    </div>



    <div className="row contact-us-landing bg-white p-md-5 p-2 pt-1 pt-md-2" id='contact' style={{ fontFamily: "system-ui",padding: '6rem 1rem',marginTop: '88rem' }}> 

    <div className="row" id='contact1'>

        <div className="col-12 section_tab_title d-flex justify-content-center align-center">Contact Us</div>

    </div>

    <div className="row d-flex justify-content-center align-center p-md-5">

        <div className="col-md-5 mb-2">

            <div className="row gy-2 mx-auto mx-2">

                <div className="col-md-12 contact_us_landing">

                    <div className="icon_box_landing" style={{ padding: '20px', backgroundColor: '#ede7f6', borderRadius: '8px' }}>

                        <i className="fas fa-map-marker-alt" style={{ fontSize: '2rem', color: '#3d1f91' }}></i>

                        <h4>Our Location</h4>

                        <p> 7RXJ+J8X, South Block, Fourth Floor, New OCAC Towers, Acharya Vihar, Bhubaneswar, Odisha 751013</p>

                    </div>

                </div>

                <div className="col-md-12 contact_us_landing">

                    <div className="icon_box_landing" style={{ padding: '20px', backgroundColor: '#ede7f6', borderRadius: '8px' }}>

                        <i className="fas fa-phone" style={{ transform: 'rotate(90deg)', fontSize: '2rem', color: '#3d1f91' }}></i>

                        <h4>Phone Number</h4>

                        <p>(+91)81 414 257 9980</p>

                    </div>

                </div>

                <div className="col-md-12 contact_us_landing">

                    <div className="icon_box_landing" style={{ padding: '20px', backgroundColor: '#ede7f6', borderRadius: '8px' }}>

                        <i className="fas fa-envelope" style={{ fontSize: '2rem', color: '#3d1f91' }}></i>

                        <h4>Email Address</h4>

                        <p>info@ajatus.co.in</p>

                    </div>

                </div>

            </div>

        </div>

        <div className="col-md-6 ml-md-5">

            <div className="contact-form p-md-5 p-4" style={{ backgroundColor: '#3d1f91', borderRadius: '8px', color: 'white' }}>

                <div className="mb-3">

                    <input type="text" className="form-control" placeholder="Your Name" style={{ borderRadius: '8px', border: 'none', padding: '15px' }} />

                </div>

                <div className="mb-3">

                    <input type="email" className="form-control" placeholder="Your Email" style={{ borderRadius: '8px', border: 'none', padding: '15px' }} />

                </div>

                <div className="mb-3">

                    <input type="text" className="form-control" placeholder="Your Phone" style={{ borderRadius: '8px', border: 'none', padding: '15px' }} />

                </div>

                <div className="mb-3">

                    <textarea className="form-control" rows="4" placeholder="Your Message" style={{ borderRadius: '8px', border: 'none', padding: '15px' }}></textarea>

                </div>

                <div className="text-center">

                    <button type="button" className="btn" style={{ backgroundColor: '#ff7675', borderRadius: '8px', color: 'white', padding: '15px 30px' }}>Send Message</button>

                </div>

            </div>

        </div>

    </div>

</div>



      <div className="row" style={{ backgroundColor: 'rgb(213 227 250 / 45%)', minHeight: '70px' }}>

        <div className="col-12 d-flex justify-content-center align-center m-auto" >

          <footer>

            <p style={{ color: 'rgb(67, 65, 117)', fontSize: '1rem' }}>&copy; 2024 Ajatus Software Pvt Ltd. All rights reserved.</p>

          </footer>

        </div>

      </div>

    </div>

       

    </>

  );

}



export default LandingPage;



