import React, { useState, useEffect } from 'react';
import './Leaves.css';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../Authenticaltion/api'
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { format } from 'date-fns';

const Leaves = () => {
  // ... other state variables ...
  const [isLoading, setIsLoading] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [userLeaves, setUserLeaves] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [page, setPage] = useState(1); // Start from page 1
  const pageSize = 50; // Default to 50 items per page
  const [hasMore, setHasMore] = useState(true); // To check if there's more data to fetch
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    leaveType: null,
    duration: null,
    status: null,
  });

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  const updateFilter = (filterName, value) => {
    console.log("Filter name is", filterName);
    console.log("Filter value is", value)
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };
  const fetchUserLeaves = async () => {
    console.log("Filter data ", filters)
    setIsLoading(true)
    try {
      const response = await api.get('/myLeaves',{
        params: {
            page,
            pageSize,
        },
    });
      if (response.status === 200) {
        console.log(response.data.leaves)
        setUserLeaves(response.data.leaves);
        if (response.data.leaves.length < pageSize) {
          setHasMore(false);
      } else {
          setHasMore(true);
      }
      } else {
        console.error('Error fetching leaves:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching leaves:', error);
    }
    finally {
      setIsLoading(false)
      setFilters({
        startDate: null,
        endDate: null,
        leaveType: null,
        duration: null,
        status: null,
      });
    }
  };
  const getLatestStatus = (leave) => {
    if (leave.statusHistory && leave.statusHistory.length > 0) {
      return leave.statusHistory[leave.statusHistory.length - 1].status;
    }
    return 'Pending';
  };
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'status-badge warning';
      case 'rejected':
        return 'status-badge danger';
      case 'approved':
        return 'status-badge success';
      default:
        return 'status-badge info'; // Default class if status doesn't match any of the above
    }
  };

  const fetchLeaveTypes = async () => {
    try {
      const response = await api.get('/companyLeaveTypeFinder'); // Replace with your actual API endpoint
      if (response.status === 200) {
        console.log(response.data)
        setLeaveTypes(response.data);
      } else {
        console.error('Error fetching leave types:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching leave types:', error);
    }
  };
  useEffect(() => {
    fetchUserLeaves();
  }, [page]);
  useEffect(() => {
    fetchLeaveTypes(); // Fetch leave types on component mount
  }, []);


  const handleDeleteLeave = async (id) => {
    try {
      const response = await api.delete(`/Deleteleave`, { params: { id } });

      if (response.status === 200) {
        toast.success("Leave deleted successfully");
        setShowDialog(false);
        fetchUserLeaves()

      } else {
        toast.error("An error occurred while deleting leave");
      }

    }
    catch (err) {
      toast.error("An error occurred while deleting leave");
    }
  };

  const handleFilter = async (filters) => {
    try {
      console.log(filters)
      setIsLoading(true);
      const response = await api.get('/getUserApplyLeaves', { params: filters });
      if (response.status === 200) {
        setUserLeaves(response.data.leaves);
      }
      else {

      }

    } catch (err) {
      toast.error(err)

    }
    finally {
      setIsLoading(false);

    }
    console.log("filter calling", filters)
  }
  const handleViewDetails =async (leaveId) => {
    const Result=await api.get( `/viewMyLeaveDetails/${leaveId}`)
    console.log("Result is print",Result)
    setSelectedLeave(Result.data.leaves[0]);
    setShowDialog(true);
  };
  const handleAutomaticDownload = (documentUrl, originalFilename) => {
    const link = document.createElement('a');
    link.href = documentUrl;
    link.download = originalFilename;

    // Trigger the download
    link.click();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd-MM-yyyy');
  };
  const calculateDays = () => {
    if (selectedLeave?.Days && selectedLeave?.Duration) {
      const adjustedDuration = (selectedLeave.Duration === 0.5 || selectedLeave.Duration === 0.6) ? 0.5 : selectedLeave.Duration;
      const totalDays = selectedLeave.Days * adjustedDuration;
      return totalDays // Adjust this logic based on the specific requirement
    }
    return 0; // Default value if data is not available
  };
  const handlePageChange = (newPage) => {
    if (newPage > 0 && (newPage !== page || hasMore)) {
        setPage(newPage);
    }
};

  return (
    <>
      {/* ... other JSX content ... */}
      <div class=" shadow p-3 bg-white rounded" style={{minWidth: '100%'}}>
        <div class="h5 pb-2 mt-2 mb-2 border-bottom text-primary">
          My Leaves
        </div>
        
        <div className="apply-leave" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <div className="col-md-2 ">
          <a href="/apply-leave" className="btn btn-primary ml-2 mr-2">Apply Leave</a>
        </div>
        </div>
        
        <div className="row g-4 mt-2">
          {userLeaves.length > 0 ? (
            userLeaves.map((leave) => (
              <div className="col-xl-3 col-lg-4 col-md-6" key={leave.ApplicationID}>
                <div 
                  className='card leave-card h-100' 
                  style={{ 
                    cursor: 'pointer', 
                    position: 'relative',
                    borderRadius: '15px',
                    border: 'none',
                    boxShadow: '0 2px 20px rgba(0,0,0,0.1)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    overflow: 'hidden',
                    background: '#ffffff'
                  }}
                  onClick={() => handleViewDetails(leave.ApplicationID)}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'translateY(-5px)';
                    e.currentTarget.style.boxShadow = '0 5px 25px rgba(0,0,0,0.15)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.boxShadow = '0 2px 20px rgba(0,0,0,0.1)';
                  }}
                >
                  {/* Leave Type Header with Integrated Status */}
                  <div className='card-header border-0 py-4' 
                    style={{ 
                      background: 'linear-gradient(135deg, #4B6CB7 0%, #182848 100%)',
                      color: 'white',
                      position: 'relative'
                    }}
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <h6 className='mb-0 fw-bold' style={{ fontSize: '1.1rem' }}>
                        {leave.type}
                      </h6>
                      <span 
                        className={`status-badge ${leave.finalstatus === 'Pending' ? 'bg-warning' : 
                                  leave.finalstatus === 'Approved' ? 'bg-success' : 
                                  leave.finalstatus === 'Rejected' ? 'bg-danger' : 'bg-info'}`}
                        style={{
                          padding: '4px 12px',
                          borderRadius: '20px',
                          fontSize: '0.8rem',
                          fontWeight: '600',
                          boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                        }}
                      >
                        {leave.finalstatus}
                      </span>
                    </div>
                  </div>

                  <div className='card-body p-4'>
                    {/* Main Info Section */}
                    <div className='info-section mb-3'>
                      {/* Leave Sub Type */}
                      {leave.subType && (
                        <div className='mb-3 pb-2' style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
                          <div className='d-flex align-items-center'>
                            <i className="fas fa-tag me-2" style={{ color: '#4B6CB7' }}></i>
                            <div>
                              <label className='text-muted d-block' style={{ fontSize: '0.75rem' }}>Sub Type</label>
                              <div className='fw-semibold' style={{ color: '#2643FF' }}>
                                {leave.subType}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Duration with Icon */}
                      <div className='mb-3 pb-2' style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
                        <div className='d-flex align-items-center'>
                          <i className="fa fa-clock me-2" style={{ color: '#4B6CB7' }}></i>
                          <div>
                            <label className='text-muted d-block' style={{ fontSize: '0.75rem' }}>Duration</label>
                            <div className='fw-semibold'>
                              {leave.Duration === 0.5 ? "First Half" :
                               leave.Duration === 0.6 ? "Second Half" :
                               leave.Duration === 1.0 ? "Full Day" : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Date Range Box */}
                    <div className='date-range-box p-3' style={{ 
                      background: 'rgba(75, 108, 183, 0.05)', 
                      borderRadius: '12px',
                      border: '1px solid rgba(75, 108, 183, 0.1)'
                    }}>
                      <div className='d-flex justify-content-between'>
                        {/* From Date */}
                        <div className='date-item'>
                          <label className='text-muted d-block mb-1' style={{ fontSize: '0.75rem' }}>From</label>
                          <div className='d-flex align-items-center'>
                            <i className="fa fa-calendar me-2" style={{ color: '#4B6CB7' }}></i>
                            <span className='fw-semibold' style={{ fontSize: '0.9rem',fontWeight: 'bold' }}>{leave.startDate}</span>
                          </div>
                        </div>

                        {/* Divider */}
                        <div className='mx-2 align-self-center' style={{ 
                          width: '1px', 
                          height: '30px', 
                          background: 'rgba(75, 108, 183, 0.2)' 
                        }}></div>

                        {/* To Date */}
                        <div className='date-item'>
                          <label className='text-muted d-block mb-1' style={{ fontSize: '0.75rem' }}>To</label>
                          <div className='d-flex align-items-center'>
                            <i className="fa fa-calendar me-2" style={{ color: '#4B6CB7' }}></i>
                            <span className='fw-semibold' style={{ fontSize: '0.9rem' }}>{leave.endDate}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* View Details Link */}
                    <div className='text-center mt-3'>
                      <span className='view-details-link' style={{ 
                        fontSize: '0.85rem', 
                        color: '#4B6CB7',
                        cursor: 'pointer'
                      }}>
                        <i className="fa fa-info-circle me-1"></i>
                        View Details
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12">
              <div className="text-center p-5" style={{ 
                background: 'rgba(75, 108, 183, 0.05)', 
                borderRadius: '15px',
                border: '1px dashed rgba(75, 108, 183, 0.2)'
              }}>
                <i className="fas fa-calendar-times mb-3" style={{ fontSize: '3rem', color: '#4B6CB7' }}></i>
                <p className="mb-0" style={{ color: '#666' }}>No leaves found</p>
              </div>
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls mt-4" style={{ 
          display: 'flex', 
          justifyContent: 'center',
          gap: '10px'
        }}>
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="btn"
            style={{
              background: page === 1 ? '#e0e0e0' : 'linear-gradient(135deg, #4B6CB7 0%, #182848 100%)',
              color: page === 1 ? '#666' : 'white',
              border: 'none',
              borderRadius: '8px',
              padding: '8px 20px',
              transition: 'all 0.2s ease'
            }}
          >
            <i className="fas fa-chevron-left me-2"></i>
            Previous
          </button>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={!hasMore}
            className="btn"
            style={{
              background: !hasMore ? '#e0e0e0' : 'linear-gradient(135deg, #4B6CB7 0%, #182848 100%)',
              color: !hasMore ? '#666' : 'white',
              border: 'none',
              borderRadius: '8px',
              padding: '8px 20px',
              transition: 'all 0.2s ease'
            }}
          >
            Next
            <i className="fas fa-chevron-right ms-2"></i>
          </button>
        </div>
      </div >

      <Dialog 
        className="leave-details-dialog" 
        visible={showDialog} 
        onHide={() => setShowDialog(false)}
        style={{ 
          width: '90vw', 
          maxWidth: '800px',
          margin: '20px',
          borderRadius: '15px'
        }}
        contentStyle={{
          padding: 0,
          borderRadius: '15px',
          overflow: 'hidden'
        }}
        showHeader={false}
        modal
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
        blockScroll
        dismissableMask
      >
        <div className='card shadow-none m-0' style={{ borderRadius: '15px', overflow: 'hidden' }}>
          {/* Header */}
          <div className='card-header border-0 py-4' 
            style={{ 
              background: 'linear-gradient(135deg, #4B6CB7 0%, #182848 100%)',
              position: 'relative'
            }}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <div className='col'>
                <h5 className='text-white mb-0 fw-bold'>{selectedLeave?.type}</h5>
                <div className='text-white-50 mt-1' style={{ fontSize: '0.9rem' }}>
                  {selectedLeave?.Duration === 0.5 ? "First Half" : 
                   selectedLeave?.Duration === 0.6 ? "Second Half" : 
                   selectedLeave?.Duration === 1.0 ? "Full Day" : 
                   `${selectedLeave?.Duration} ${selectedLeave?.subType}`}
                </div>
              </div>
              <div className='col-auto'>
                <button 
                  className='btn-close btn-close-white' 
                  onClick={() => setShowDialog(false)}
                  style={{ 
                    opacity: 0.8,
                    transition: 'all 0.2s ease',
                    ':hover': {
                      opacity: 1,
                      transform: 'scale(1.1)'
                    }
                  }}
                ></button>
              </div>
            </div>
          </div>

          {/* Body with Scrollable Content */}
          <div className='dialog-content' style={{ 
            maxHeight: 'calc(85vh - 100px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: '#f8f9fa'
          }}>
            <div className='card-body p-4'>
              {/* Status Section */}
              <div className='status-section mb-4 p-3' style={{ 
                background: 'white',
                borderRadius: '12px',
                boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease'
              }}>
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                  <span className='text-muted mb-2 mb-sm-0'>Current Status</span>
                  <span className={`badge ${
                    selectedLeave?.finalstatus === 'Approved' ? 'bg-success' : 
                    selectedLeave?.finalstatus === 'Rejected' ? 'bg-danger' : 
                    'bg-warning'
                  } px-3 py-2`} style={{ 
                    borderRadius: '20px',
                    fontSize: '0.9rem',
                    minWidth: '100px',
                    textAlign: 'center'
                  }}>
                    {selectedLeave?.finalstatus}
                  </span>
                </div>
              </div>

              {/* Leave Details Grid */}
              <div className='row g-3 mb-4'>
                <div className='col-md-6'>
                  <div className='detail-card p-3' style={{ 
                    background: 'white',
                    borderRadius: '12px',
                    height: '100%',
                    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
                    transition: 'transform 0.2s ease',
                    ':hover': {
                      transform: 'translateY(-2px)'
                    }
                  }}>
                    <div className='d-flex align-items-center mb-3'>
                      <div className='icon-circle me-2' style={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        background: 'rgba(75, 108, 183, 0.1)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <i className='fa fa-calendar-alt' style={{ color: '#4B6CB7' }}></i>
                      </div>
                      <span className='text-muted'>Duration</span>
                    </div>
                    <div className='fw-bold' style={{ fontSize: '1.1rem' }}>
                      {calculateDays()} {calculateDays() <= 1 ? "day" : "days"}
                    </div>
                  </div>
                </div>
                
                <div className='col-md-6'>
                  <div className='detail-card p-3' style={{ 
                    background: 'white',
                    borderRadius: '12px',
                    height: '100%',
                    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
                    transition: 'transform 0.2s ease',
                    ':hover': {
                      transform: 'translateY(-2px)'
                    }
                  }}>
                    <div className='d-flex align-items-center mb-3'>
                      <div className='icon-circle me-2' style={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        background: 'rgba(75, 108, 183, 0.1)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <i className='fa fa-clock' style={{ color: '#4B6CB7' }}></i>
                      </div>
                      <span className='text-muted'>Date Range</span>
                    </div>
                    <div className='date-range'>
                      <div className='mb-2 d-flex align-items-center'>
                        <span className='text-muted me-2' style={{ fontSize: '0.85rem' }}>From:</span>
                        <span className='fw-semibold'>{selectedLeave?.startDate ? formatDate(selectedLeave.startDate) : 'No start date'}</span>
                      </div>
                      <div className='d-flex align-items-center'>
                        <span className='text-muted me-2' style={{ fontSize: '0.85rem' }}>To:</span>
                        <span className='fw-semibold'>{selectedLeave?.endDate ? formatDate(selectedLeave.endDate) : 'No end date'}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {selectedLeave?.AttachedDocumnet?.pdf_url && (
                  <div className='col-12'>
                    <div className='detail-card p-3' style={{ 
                      background: 'white',
                      borderRadius: '12px',
                      boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
                      transition: 'transform 0.2s ease',
                      ':hover': {
                        transform: 'translateY(-2px)'
                      }
                    }}>
                      <div className='d-flex align-items-center mb-3'>
                        <div className='icon-circle me-2' style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '50%',
                          background: 'rgba(75, 108, 183, 0.1)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          <i className='fa fa-file-alt' style={{ color: '#4B6CB7' }}></i>
                        </div>
                        <span className='text-muted'>Attached Document</span>
                      </div>
                      <a
                        href={selectedLeave.AttachedDocumnet.pdf_url}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(selectedLeave.AttachedDocumnet.pdf_url, '_blank');
                        }}
                        className='btn btn-light btn-sm'
                        style={{
                          color: '#4B6CB7',
                          textDecoration: 'none',
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: '8px'
                        }}
                      >
                        <i className='fa fa-download'></i>
                        {selectedLeave.AttachedDocumnet.originalFilename}
                      </a>
                    </div>
                  </div>
                )}
              </div>

              {/* Leave History Section */}
              <div className='history-section'>
                <h6 className='mb-3 fw-bold'>Leave History</h6>
                <div className='timeline' style={{ 
                  background: 'white',
                  borderRadius: '12px',
                  padding: '20px',
                  boxShadow: '0 2px 12px rgba(0,0,0,0.05)'
                }}>
                  {selectedLeave?.statusHistory?.map((historyItem, index) => (
                    <div key={index} className='timeline-item mb-4' style={{
                      position: 'relative',
                      paddingLeft: '30px',
                      borderLeft: '2px solid #e9ecef'
                    }}>
                      <div className='timeline-marker' style={{
                        position: 'absolute',
                        left: '-6px',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        background: index === 0 ? '#4B6CB7' : '#e9ecef'
                      }}></div>
                      <div className='d-flex justify-content-between align-items-start mb-2'>
                        <div>
                          <span className='fw-bold' style={{ color: '#4B6CB7' }}>{historyItem.updatedBy}</span>
                          <span className='text-muted ms-2' style={{ fontSize: '0.85rem' }}>{historyItem.dateChanged}</span>
                        </div>
                        {index > 0 && (
                          <span className={`badge ${
                            historyItem.status === 'Approved' ? 'bg-success' :
                            historyItem.status === 'Rejected' ? 'bg-danger' :
                            'bg-warning'
                          } px-2`}>
                            {historyItem.status}
                          </span>
                        )}
                      </div>
                      <div className='timeline-content p-3' style={{
                        background: '#f8f9fa',
                        borderRadius: '8px',
                        fontSize: '0.95rem',
                        lineHeight: '1.5'
                      }}>
                        <div 
                          dangerouslySetInnerHTML={createMarkup(historyItem.statusReson)}
                          className="leave-reason"
                          style={{
                            '& p': {
                              margin: 0,
                              padding: 0
                            },
                            '& span': {
                              color: 'inherit'
                            }
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Delete Button - Only show if conditions met */}
              {selectedLeave?.statusHistory?.length === 1 && 
               new Date(formatDate(selectedLeave.startDate).split("-").reverse().join("-")).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) && (
                <div className="d-flex justify-content-end mt-4">
                  <button 
                    className='btn btn-danger'
                    onClick={() => handleDeleteLeave(selectedLeave?.id)}
                    style={{
                      borderRadius: '8px',
                      padding: '8px 20px'
                    }}
                  >
                    <i className='fa fa-trash-alt me-2'></i>
                    Delete Leave
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <ToastContainer />
    </>
  );
}
export default Leaves;