import React, { useState } from 'react';
import './ResignationPage.css';

const ResignationPage = () => {
  const [formData, setFormData] = useState({
    employeeId: '',
    fullName: '',
    department: '',
    position: '',
    resignationDate: '',
    reason: '',
    noticePeriod: '',
    additionalComments: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.employeeId.trim()) newErrors.employeeId = 'Employee ID is required';
    if (!formData.fullName.trim()) newErrors.fullName = 'Full Name is required';
    if (!formData.department.trim()) newErrors.department = 'Department is required';
    if (!formData.resignationDate) newErrors.resignationDate = 'Resignation Date is required';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // TODO: Implement submission logic (e.g., API call)
      console.log('Resignation Form Submitted', formData);
      alert('Resignation request submitted successfully!');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="bg-white shadow-xl rounded-xl p-8 w-full max-w-2xl">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Employee Resignation Form</h1>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
          <div>
  <label htmlFor="employeeId" className="block text-sm font-medium text-gray-700 mb-2">
    Employee ID
  </label>
  <input
    type="text"
    id="employeeId"
    name="employeeId"
    value={formData.employeeId}
    onChange={handleChange}
    required
    pattern="\d+" /* Only allows digits */
    title="Employee ID must be a numeric value"
    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
      errors.employeeId ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-blue-500'
    }`}
    placeholder="Enter Employee ID"
  />
  {errors.employeeId && (
    <p className="error-message">{errors.employeeId}</p>
  )}
</div>

            
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-2">
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                  ${errors.fullName ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-blue-500'}`}
                placeholder="Enter Full Name"
              />
              {errors.fullName && (
                <p className="text-red-500 text-xs mt-1">{errors.fullName}</p>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="department" className="block text-sm font-medium text-gray-700 mb-2">
                Department
              </label>
              <select
                id="department"
                name="department"
                value={formData.department}
                onChange={handleChange}
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                  ${errors.department ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-blue-500'}`}
              >
                <option value="">Select Department</option>
                <option value="HR">Human Resources</option>
                <option value="IT">Information Technology</option>
                <option value="Finance">Finance</option>
                <option value="Marketing">Marketing</option>
                <option value="Sales">Sales</option>
                <option value="Operations">Operations</option>
              </select>
              {errors.department && (
                <p className="text-red-500 text-xs mt-1">{errors.department}</p>
              )}
            </div>
            
            <div>
              <label htmlFor="position" className="block text-sm font-medium text-gray-700 mb-2">
                Current Position
              </label>
              <input
                type="text"
                id="position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Current Position"
              />
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="resignationDate" className="block text-sm font-medium text-gray-700 mb-2">
                Resignation Date
              </label>
              <input
                type="date"
                id="resignationDate"
                name="resignationDate"
                value={formData.resignationDate}
                onChange={handleChange}
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 
                  ${errors.resignationDate ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-blue-500'}`}
              />
              {errors.resignationDate && (
                <p className="text-red-500 text-xs mt-1">{errors.resignationDate}</p>
              )}
            </div>
            
            <div>
              <label htmlFor="noticePeriod" className="block text-sm font-medium text-gray-700 mb-2">
                Notice Period (Weeks)
              </label>
              <input
                type="number"
                id="noticePeriod"
                name="noticePeriod"
                value={formData.noticePeriod}
                onChange={handleChange}
                min="0"
                max="12"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Notice Period"
              />
            </div>
          </div>
          
          <div>
            <label htmlFor="reason" className="block text-sm font-medium text-gray-700 mb-2">
              Reason for Resignation
            </label>
            <select
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select Reason</option>
              <option value="career_growth">Career Growth</option>
              <option value="personal_reasons">Personal Reasons</option>
              <option value="better_opportunity">Better Opportunity</option>
              <option value="relocation">Relocation</option>
              <option value="other">Other</option>
            </select>
          </div>
          
          <div>
            <label htmlFor="additionalComments" className="block text-sm font-medium text-gray-700 mb-2">
              Additional Comments
            </label>
            <textarea
              id="additionalComments"
              name="additionalComments"
              value={formData.additionalComments}
              onChange={handleChange}
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Optional: Provide any additional details or feedback"
            ></textarea>
          </div>
          
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Submit Resignation Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResignationPage;